/* import __COLOCATED_TEMPLATE__ from './phone-modal.hbs'; */
import EmberObject, { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { tracked } from "@glimmer/tracking";
import Component from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import { isEmpty } from "@ember/utils";


export default class PhoneModal extends Component {
  @service dialog;
  @service currentUser;
  @tracked accountUsername;
  @tracked isShowRegion = true;
  @tracked phoneNumber;
  @tracked currentTelIdx = 0;
  smsCountdown = 0; // 倒计时的秒数
  @tracked isSmsButtonDisabled = false; // 控制按钮是否可点击
  @tracked formSubmitted = false;
  @tracked nextloadin = false;
  isDeveloper = false;
  @tracked regionData = [
    {
      "iddcode": "+86",
      "name": "China Mainland",
      "minlength": 11,
      "rule": "/^1[0-9]{10}$/",
      "common": true,
      "en": "China's mainland",
      "value": "China's mainland"
    },
    {
      "iddcode": "+852",
      "name": "China Hong Kong",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": true,
      "en": "Hong Kong",
      "value": "Hong Kong"
    },
    {
      "iddcode": "+853",
      "name": "China Macau",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": true,
      "en": "Macau",
      "value": "Macau"
    },
    {
      "iddcode": "+886",
      "name": "China Taiwan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": true,
      "en": "Taiwan",
      "value": "Taiwan"
    },
    {
      "iddcode": "+93",
      "name": "Afghanistan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Afghanistan",
      "value": "Afghanistan"
    },
    {
      "iddcode": "+355",
      "name": "Albania",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Albania",
      "value": "Albania"
    },
    {
      "iddcode": "+213",
      "name": "Algeria",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Algeria",
      "value": "Algeria"
    },
    {
      "iddcode": "+1684",
      "name": "American Samoa",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "American Samoa",
      "value": "American Samoa"
    },
    {
      "iddcode": "+376",
      "name": "Andorra",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Andorra",
      "value": "Andorra"
    },
    {
      "iddcode": "+244",
      "name": "Angola",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Angola",
      "value": "Angola"
    },
    {
      "iddcode": "+1264",
      "name": "Anguilla",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Anguilla",
      "value": "Anguilla"
    },
    {
      "iddcode": "+1268",
      "name": "Antigua and Barbuda",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Antigua and Barbuda",
      "value": "Antigua and Barbuda"
    },
    {
      "iddcode": "+54",
      "name": "Argentina",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Argentina",
      "value": "Argentina"
    },
    {
      "iddcode": "+374",
      "name": "Armenia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Armenia",
      "value": "Armenia"
    },
    {
      "iddcode": "+297",
      "name": "Aruba",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Aruba",
      "value": "Aruba"
    },
    {
      "iddcode": "+247",
      "name": "Ascension island",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Ascension island",
      "value": "Ascension island"
    },
    {
      "iddcode": "+61",
      "name": "Australia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Australia",
      "value": "Australia"
    },
    {
      "iddcode": "+43",
      "name": "Austria",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Austria",
      "value": "Austria"
    },
    {
      "iddcode": "+994",
      "name": "Azerbaijan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Azerbaijan",
      "value": "Azerbaijan"
    },
    {
      "iddcode": "+1242",
      "name": "Bahamas",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bahamas",
      "value": "Bahamas"
    },
    {
      "iddcode": "+973",
      "name": "Bahrain",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bahrain",
      "value": "Bahrain"
    },
    {
      "iddcode": "+880",
      "name": "Bangladesh",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bangladesh",
      "value": "Bangladesh"
    },
    {
      "iddcode": "+1246",
      "name": "Barbados",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Barbados",
      "value": "Barbados"
    },
    {
      "iddcode": "+375",
      "name": "Belarus",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Belarus",
      "value": "Belarus"
    },
    {
      "iddcode": "+32",
      "name": "Belgium",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Belgium",
      "value": "Belgium"
    },
    {
      "iddcode": "+501",
      "name": "Belize",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Belize",
      "value": "Belize"
    },
    {
      "iddcode": "+229",
      "name": "Benin",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Benin",
      "value": "Benin"
    },
    {
      "iddcode": "+1441",
      "name": "Bermuda",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bermuda",
      "value": "Bermuda"
    },
    {
      "iddcode": "+975",
      "name": "Bhutan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bhutan",
      "value": "Bhutan"
    },
    {
      "iddcode": "+591",
      "name": "Bolivia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bolivia, Plurinational",
      "value": "Bolivia, Plurinational"
    },
    {
      "iddcode": "+599",
      "name": "Bonaire Sint Eustat",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bonaire Sint Eustat",
      "value": "Bonaire Sint Eustat"
    },
    {
      "iddcode": "+387",
      "name": "Bosnia and Herzegovina",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bosnia and Herzegovina",
      "value": "Bosnia and Herzegovina"
    },
    {
      "iddcode": "+267",
      "name": "Botswana",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Botswana",
      "value": "Botswana"
    },
    {
      "iddcode": "+55",
      "name": "Brazil",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Brazil",
      "value": "Brazil"
    },
    {
      "iddcode": "+673",
      "name": "Brunei Darussalam",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Brunei Darussalam",
      "value": "Brunei Darussalam"
    },
    {
      "iddcode": "+359",
      "name": "Bulgaria",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Bulgaria",
      "value": "Bulgaria"
    },
    {
      "iddcode": "+226",
      "name": "Burkina Faso",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Burkina Faso",
      "value": "Burkina Faso"
    },
    {
      "iddcode": "+257",
      "name": "Burundi",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Burundi",
      "value": "Burundi"
    },
    {
      "iddcode": "+855",
      "name": "Cambodia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cambodia",
      "value": "Cambodia"
    },
    {
      "iddcode": "+237",
      "name": "Cameroon",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cameroon",
      "value": "Cameroon"
    },
    {
      "iddcode": "+1",
      "name": "Canada",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Canada",
      "value": "Canada"
    },
    {
      "iddcode": "+238",
      "name": "Cape Verde",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cape Verde",
      "value": "Cape Verde"
    },
    {
      "iddcode": "+1345",
      "name": "Cayman Islands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cayman Islands",
      "value": "Cayman Islands"
    },
    {
      "iddcode": "+236",
      "name": "Central African Republic",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Central African Republic",
      "value": "Central African Republic"
    },
    {
      "iddcode": "+235",
      "name": "Chad",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Chad",
      "value": "Chad"
    },
    {
      "iddcode": "+56",
      "name": "Chile",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Chile",
      "value": "Chile"
    },
    {
      "iddcode": "+57",
      "name": "Colombia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Colombia",
      "value": "Colombia"
    },
    {
      "iddcode": "+269",
      "name": "Comoros",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Comoros",
      "value": "Comoros"
    },
    {
      "iddcode": "+682",
      "name": "Cook Islands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cook Islands",
      "value": "Cook Islands"
    },
    {
      "iddcode": "+506",
      "name": "Costa Rica",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Costa Rica",
      "value": "Costa Rica"
    },
    {
      "iddcode": "+225",
      "name": "Cote d'Ivoire",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Côte d'Ivoire",
      "value": "Côte d'Ivoire"
    },
    {
      "iddcode": "+385",
      "name": "Croatia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Croatia",
      "value": "Croatia"
    },
    {
      "iddcode": "+53",
      "name": "Cuba",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cuba",
      "value": "Cuba"
    },
    {
      "iddcode": "+599",
      "name": "Curacao",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Curacao",
      "value": "Curacao"
    },
    {
      "iddcode": "+357",
      "name": "Cyprus",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Cyprus",
      "value": "Cyprus"
    },
    {
      "iddcode": "+420",
      "name": "Czech Republic",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Czech Republic",
      "value": "Czech Republic"
    },
    {
      "iddcode": "+45",
      "name": "Denmark",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Denmark",
      "value": "Denmark"
    },
    {
      "iddcode": "+253",
      "name": "Djibouti",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Djibouti",
      "value": "Djibouti"
    },
    {
      "iddcode": "+1767",
      "name": "Dominica",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Dominica",
      "value": "Dominica"
    },
    {
      "iddcode": "+1809",
      "name": "Dominican Republic",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Dominican Republic",
      "value": "Dominican Republic"
    },
    {
      "iddcode": "+593",
      "name": "Ecuador",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Ecuador",
      "value": "Ecuador"
    },
    {
      "iddcode": "+20",
      "name": "Egypt",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Egypt",
      "value": "Egypt"
    },
    {
      "iddcode": "+503",
      "name": "El Salvador",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "El Salvador",
      "value": "El Salvador"
    },
    {
      "iddcode": "+240",
      "name": "Equatorial Guinea",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Equatorial Guinea",
      "value": "Equatorial Guinea"
    },
    {
      "iddcode": "+372",
      "name": "Estonia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Estonia",
      "value": "Estonia"
    },
    {
      "iddcode": "+251",
      "name": "Ethiopia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Ethiopia",
      "value": "Ethiopia"
    },
    {
      "iddcode": "+298",
      "name": "Faroe Islands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Faroe Islands",
      "value": "Faroe Islands"
    },
    {
      "iddcode": "+679",
      "name": "Fiji",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Fiji",
      "value": "Fiji"
    },
    {
      "iddcode": "+358",
      "name": "Finland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Finland",
      "value": "Finland"
    },
    {
      "iddcode": "+33",
      "name": "France",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "France",
      "value": "France"
    },
    {
      "iddcode": "+594",
      "name": "French Guiana",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "French Guiana",
      "value": "French Guiana"
    },
    {
      "iddcode": "+689",
      "name": "French Polynesia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "French Polynesia",
      "value": "French Polynesia"
    },
    {
      "iddcode": "+241",
      "name": "Gabon",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Gabon",
      "value": "Gabon"
    },
    {
      "iddcode": "+220",
      "name": "Gambia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Gambia",
      "value": "Gambia"
    },
    {
      "iddcode": "+995",
      "name": "Georgia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Georgia",
      "value": "Georgia"
    },
    {
      "iddcode": "+49",
      "name": "Germany",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Germany",
      "value": "Germany"
    },
    {
      "iddcode": "+233",
      "name": "Ghana",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Ghana",
      "value": "Ghana"
    },
    {
      "iddcode": "+350",
      "name": "Gibraltar",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Gibraltar",
      "value": "Gibraltar"
    },
    {
      "iddcode": "+30",
      "name": "Greece",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Greece",
      "value": "Greece"
    },
    {
      "iddcode": "+299",
      "name": "Greenland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Greenland",
      "value": "Greenland"
    },
    {
      "iddcode": "+1473",
      "name": "Grenada",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Grenada",
      "value": "Grenada"
    },
    {
      "iddcode": "+590",
      "name": "Guadeloupe",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Guadeloupe",
      "value": "Guadeloupe"
    },
    {
      "iddcode": "+1671",
      "name": "Guam",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Guam",
      "value": "Guam"
    },
    {
      "iddcode": "+502",
      "name": "Guatemala",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Guatemala",
      "value": "Guatemala"
    },
    {
      "iddcode": "+224",
      "name": "Guinea",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Guinea",
      "value": "Guinea"
    },
    {
      "iddcode": "+245",
      "name": "Guinea Bissau",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Guinea Bissau",
      "value": "Guinea Bissau"
    },
    {
      "iddcode": "+592",
      "name": "Guyana",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Guyana",
      "value": "Guyana"
    },
    {
      "iddcode": "+509",
      "name": "Haiti",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Haiti",
      "value": "Haiti"
    },
    {
      "iddcode": "+504",
      "name": "Honduras",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Honduras",
      "value": "Honduras"
    },
    {
      "iddcode": "+36",
      "name": "Hungary",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Hungary",
      "value": "Hungary"
    },
    {
      "iddcode": "+354",
      "name": "Iceland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Iceland",
      "value": "Iceland"
    },
    {
      "iddcode": "+91",
      "name": "India",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "India",
      "value": "India"
    },
    {
      "iddcode": "+62",
      "name": "Indonesia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Indonesia",
      "value": "Indonesia"
    },
    {
      "iddcode": "+98",
      "name": "Iran",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Iran",
      "value": "Iran"
    },
    {
      "iddcode": "+964",
      "name": "Iraq",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Iraq",
      "value": "Iraq"
    },
    {
      "iddcode": "+353",
      "name": "Ireland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Ireland",
      "value": "Ireland"
    },
    {
      "iddcode": "+972",
      "name": "Israel",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Israel",
      "value": "Israel"
    },
    {
      "iddcode": "+39",
      "name": "Italy",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Italy",
      "value": "Italy"
    },
    {
      "iddcode": "+1876",
      "name": "Jamaica",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Jamaica",
      "value": "Jamaica"
    },
    {
      "iddcode": "+81",
      "name": "Japan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Japan",
      "value": "Japan"
    },
    {
      "iddcode": "+962",
      "name": "Jordan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Jordan",
      "value": "Jordan"
    },
    {
      "iddcode": "+7",
      "name": "Kazakhstan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Kazakhstan",
      "value": "Kazakhstan"
    },
    {
      "iddcode": "+254",
      "name": "Kenya",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Kenya",
      "value": "Kenya"
    },
    {
      "iddcode": "+686",
      "name": "Kiribati",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Kiribati",
      "value": "Kiribati"
    },
    {
      "iddcode": "+82",
      "name": "Republic of Korea",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Republic of Korea",
      "value": "Republic of Korea"
    },
    {
      "iddcode": "+965",
      "name": "Kuwait",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Kuwait",
      "value": "Kuwait"
    },
    {
      "iddcode": "+996",
      "name": "Kyrgyzstan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Kyrgyzstan",
      "value": "Kyrgyzstan"
    },
    {
      "iddcode": "+856",
      "name": "Laos",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Laos",
      "value": "Laos"
    },
    {
      "iddcode": "+371",
      "name": "Latvia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Latvia",
      "value": "Latvia"
    },
    {
      "iddcode": "+961",
      "name": "Lebanon",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Lebanon",
      "value": "Lebanon"
    },
    {
      "iddcode": "+266",
      "name": "Lesotho",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Lesotho",
      "value": "Lesotho"
    },
    {
      "iddcode": "+231",
      "name": "Liberia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Liberia",
      "value": "Liberia"
    },
    {
      "iddcode": "+218",
      "name": "Libya",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Libya",
      "value": "Libya"
    },
    {
      "iddcode": "+423",
      "name": "Liechtenstein",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Liechtenstein",
      "value": "Liechtenstein"
    },
    {
      "iddcode": "+370",
      "name": "Lithuania",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Lithuania",
      "value": "Lithuania"
    },
    {
      "iddcode": "+352",
      "name": "Luxembourg",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Luxembourg",
      "value": "Luxembourg"
    },
    {
      "iddcode": "+389",
      "name": "Macedonia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Macedonia",
      "value": "Macedonia"
    },
    {
      "iddcode": "+261",
      "name": "Madagascar",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Madagascar",
      "value": "Madagascar"
    },
    {
      "iddcode": "+265",
      "name": "Malawi",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Malawi",
      "value": "Malawi"
    },
    {
      "iddcode": "+60",
      "name": "Malaysia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Malaysia",
      "value": "Malaysia"
    },
    {
      "iddcode": "+960",
      "name": "Maldives",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Maldives",
      "value": "Maldives"
    },
    {
      "iddcode": "+223",
      "name": "Mali",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mali",
      "value": "Mali"
    },
    {
      "iddcode": "+356",
      "name": "Malta",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Malta",
      "value": "Malta"
    },
    {
      "iddcode": "+596",
      "name": "Martinique",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Martinique",
      "value": "Martinique"
    },
    {
      "iddcode": "+222",
      "name": "Mauritania",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mauritania",
      "value": "Mauritania"
    },
    {
      "iddcode": "+230",
      "name": "Mauritius",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mauritius",
      "value": "Mauritius"
    },
    {
      "iddcode": "+269",
      "name": "Mayotte",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mayotte",
      "value": "Mayotte"
    },
    {
      "iddcode": "+52",
      "name": "Mexico",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mexico",
      "value": "Mexico"
    },
    {
      "iddcode": "+373",
      "name": "Moldova",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Moldova",
      "value": "Moldova"
    },
    {
      "iddcode": "+377",
      "name": "Monaco",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Monaco",
      "value": "Monaco"
    },
    {
      "iddcode": "+976",
      "name": "Mongolia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mongolia",
      "value": "Mongolia"
    },
    {
      "iddcode": "+382",
      "name": "Montenegro",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Montenegro",
      "value": "Montenegro"
    },
    {
      "iddcode": "+1664",
      "name": "Montserrat",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Montserrat",
      "value": "Montserrat"
    },
    {
      "iddcode": "+212",
      "name": "Morocco",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Morocco",
      "value": "Morocco"
    },
    {
      "iddcode": "+258",
      "name": "Mozambique",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Mozambique",
      "value": "Mozambique"
    },
    {
      "iddcode": "+95",
      "name": "Myanmar",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Myanmar",
      "value": "Myanmar"
    },
    {
      "iddcode": "+264",
      "name": "Namibia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Namibia",
      "value": "Namibia"
    },
    {
      "iddcode": "+977",
      "name": "Nepal",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Nepal",
      "value": "Nepal"
    },
    {
      "iddcode": "+31",
      "name": "Netherlands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Netherlands",
      "value": "Netherlands"
    },
    {
      "iddcode": "+687",
      "name": "New Caledonia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "New Caledonia",
      "value": "New Caledonia"
    },
    {
      "iddcode": "+64",
      "name": "New Zealand",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "New Zealand",
      "value": "New Zealand"
    },
    {
      "iddcode": "+505",
      "name": "Nicaragua",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Nicaragua",
      "value": "Nicaragua"
    },
    {
      "iddcode": "+227",
      "name": "Niger",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Niger",
      "value": "Niger"
    },
    {
      "iddcode": "+234",
      "name": "Nigeria",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Nigeria",
      "value": "Nigeria"
    },
    {
      "iddcode": "+47",
      "name": "Norway",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Norway",
      "value": "Norway"
    },
    {
      "iddcode": "+968",
      "name": "Oman",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Oman",
      "value": "Oman"
    },
    {
      "iddcode": "+92",
      "name": "Pakistan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Pakistan",
      "value": "Pakistan"
    },
    {
      "iddcode": "+680",
      "name": "Palau",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Palau",
      "value": "Palau"
    },
    {
      "iddcode": "+930",
      "name": "Palestine",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Palestine",
      "value": "Palestine"
    },
    {
      "iddcode": "+507",
      "name": "Panama",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Panama",
      "value": "Panama"
    },
    {
      "iddcode": "+675",
      "name": "Papua New Guinea",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Papua New Guinea",
      "value": "Papua New Guinea"
    },
    {
      "iddcode": "+595",
      "name": "Paraguay",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Paraguay",
      "value": "Paraguay"
    },
    {
      "iddcode": "+51",
      "name": "Peru",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Peru",
      "value": "Peru"
    },
    {
      "iddcode": "+63",
      "name": "Philippines",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Philippines",
      "value": "Philippines"
    },
    {
      "iddcode": "+48",
      "name": "Poland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Poland",
      "value": "Poland"
    },
    {
      "iddcode": "+351",
      "name": "Portugal",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Portugal",
      "value": "Portugal"
    },
    {
      "iddcode": "+1787",
      "name": "Puerto Rico",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Puerto Rico",
      "value": "Puerto Rico"
    },
    {
      "iddcode": "+974",
      "name": "Qatar",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Qatar",
      "value": "Qatar"
    },
    {
      "iddcode": "+263",
      "name": "Republic of Zimbabwe",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Republic of Zimbabwe",
      "value": "Republic of Zimbabwe"
    },
    {
      "iddcode": "+262",
      "name": "Reunion",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Réunion",
      "value": "Réunion"
    },
    {
      "iddcode": "+40",
      "name": "Romania",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Romania",
      "value": "Romania"
    },
    {
      "iddcode": "+7",
      "name": "Russian Federation",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Russian Federation",
      "value": "Russian Federation"
    },
    {
      "iddcode": "+250",
      "name": "Rwanda",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Rwanda",
      "value": "Rwanda"
    },
    {
      "iddcode": "+1869",
      "name": "Saint Kitts and Nevis",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Saint Kitts and Nevis",
      "value": "Saint Kitts and Nevis"
    },
    {
      "iddcode": "+1758",
      "name": "Saint Lucia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Saint Lucia",
      "value": "Saint Lucia"
    },
    {
      "iddcode": "+508",
      "name": "Saint Pierre and Miquelon",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Saint Pierre and Miquelon",
      "value": "Saint Pierre and Miquelon"
    },
    {
      "iddcode": "+1784",
      "name": "Saint Vincent and the Grenadin",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Saint Vincent and the Grenadines",
      "value": "Saint Vincent and the Grenadines"
    },
    {
      "iddcode": "+685",
      "name": "Samoa",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Samoa",
      "value": "Samoa"
    },
    {
      "iddcode": "+378",
      "name": "San Marino",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "San Marino",
      "value": "San Marino"
    },
    {
      "iddcode": "+239",
      "name": "Sao Tome and Principe",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Sao Tome and Principe",
      "value": "Sao Tome and Principe"
    },
    {
      "iddcode": "+966",
      "name": "Saudi Arabia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Saudi Arabia",
      "value": "Saudi Arabia"
    },
    {
      "iddcode": "+221",
      "name": "Senegal",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Senegal",
      "value": "Senegal"
    },
    {
      "iddcode": "+381",
      "name": "Serbia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Serbia",
      "value": "Serbia"
    },
    {
      "iddcode": "+248",
      "name": "Seychelles",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Seychelles",
      "value": "Seychelles"
    },
    {
      "iddcode": "+232",
      "name": "Sierra Leone",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Sierra Leone",
      "value": "Sierra Leone"
    },
    {
      "iddcode": "+65",
      "name": "Singapore",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Singapore",
      "value": "Singapore"
    },
    {
      "iddcode": "+590",
      "name": "Sint Maarten(Dutch)",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Sint Maarten(Dutch)",
      "value": "Sint Maarten(Dutch)"
    },
    {
      "iddcode": "+421",
      "name": "Slovakia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Slovakia",
      "value": "Slovakia"
    },
    {
      "iddcode": "+386",
      "name": "Slovenia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Slovenia",
      "value": "Slovenia"
    },
    {
      "iddcode": "+677",
      "name": "Solomon Islands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Solomon Islands",
      "value": "Solomon Islands"
    },
    {
      "iddcode": "+252",
      "name": "Somalia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Somalia",
      "value": "Somalia"
    },
    {
      "iddcode": "+27",
      "name": "South Africa",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "South Africa",
      "value": "South Africa"
    },
    {
      "iddcode": "+211",
      "name": "South Sudan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "South Sudan",
      "value": "South Sudan"
    },
    {
      "iddcode": "+34",
      "name": "Spain",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Spain",
      "value": "Spain"
    },
    {
      "iddcode": "+94",
      "name": "Sri Lanka",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Sri Lanka",
      "value": "Sri Lanka"
    },
    {
      "iddcode": "+249",
      "name": "Sudan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Sudan",
      "value": "Sudan"
    },
    {
      "iddcode": "+597",
      "name": "Suriname",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Suriname",
      "value": "Suriname"
    },
    {
      "iddcode": "+268",
      "name": "Swaziland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Swaziland",
      "value": "Swaziland"
    },
    {
      "iddcode": "+46",
      "name": "Sweden",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Sweden",
      "value": "Sweden"
    },
    {
      "iddcode": "+41",
      "name": "Switzerland",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Switzerland",
      "value": "Switzerland"
    },
    {
      "iddcode": "+963",
      "name": "Syrian Arab Republic",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Syrian Arab Republic",
      "value": "Syrian Arab Republic"
    },
    {
      "iddcode": "+992",
      "name": "Tajikistan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Tajikistan",
      "value": "Tajikistan"
    },
    {
      "iddcode": "+255",
      "name": "Tanzania",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Tanzania",
      "value": "Tanzania"
    },
    {
      "iddcode": "+66",
      "name": "Thailand",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Thailand",
      "value": "Thailand"
    },
    {
      "iddcode": "+670",
      "name": "Timor",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Timor",
      "value": "Timor"
    },
    {
      "iddcode": "+228",
      "name": "Togo",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Togo",
      "value": "Togo"
    },
    {
      "iddcode": "+676",
      "name": "Tonga",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Tonga",
      "value": "Tonga"
    },
    {
      "iddcode": "+1868",
      "name": "Trinidad and Tobago",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Trinidad and Tobago",
      "value": "Trinidad and Tobago"
    },
    {
      "iddcode": "+216",
      "name": "Tunisia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Tunisia",
      "value": "Tunisia"
    },
    {
      "iddcode": "+90",
      "name": "Turkey",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Turkey",
      "value": "Turkey"
    },
    {
      "iddcode": "+993",
      "name": "Turkmenistan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Turkmenistan",
      "value": "Turkmenistan"
    },
    {
      "iddcode": "+1649",
      "name": "Turks and Caicos Islands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Turks and Caicos Islands",
      "value": "Turks and Caicos Islands"
    },
    {
      "iddcode": "+256",
      "name": "Uganda",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Uganda",
      "value": "Uganda"
    },
    {
      "iddcode": "+380",
      "name": "Ukraine",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Ukraine",
      "value": "Ukraine"
    },
    {
      "iddcode": "+971",
      "name": "United Arab Emirates",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "United Arab Emirates",
      "value": "United Arab Emirates"
    },
    {
      "iddcode": "+44",
      "name": "United Kingdom",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "United Kingdom",
      "value": "United Kingdom"
    },
    {
      "iddcode": "+1",
      "name": "United States",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "United States",
      "value": "United States"
    },
    {
      "iddcode": "+1340",
      "name": "United States Virgin Islands",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "United States Virgin Islands",
      "value": "United States Virgin Islands"
    },
    {
      "iddcode": "+598",
      "name": "Uruguay",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Uruguay",
      "value": "Uruguay"
    },
    {
      "iddcode": "+998",
      "name": "Uzbekistan",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Uzbekistan",
      "value": "Uzbekistan"
    },
    {
      "iddcode": "+678",
      "name": "Vanuatu",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Vanuatu",
      "value": "Vanuatu"
    },
    {
      "iddcode": "+58",
      "name": "Venezuela",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Venezuela",
      "value": "Venezuela"
    },
    {
      "iddcode": "+84",
      "name": "Vietnam",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Vietnam",
      "value": "Vietnam"
    },
    {
      "iddcode": "+1284",
      "name": "Virgin Islands, U.S.",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Virgin Islands, U.S.",
      "value": "Virgin Islands, U.S."
    },
    {
      "iddcode": "+967",
      "name": "Yemen",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Yemen",
      "value": "Yemen"
    },
    {
      "iddcode": "+260",
      "name": "Zambia",
      "minlength": 6,
      "rule": "/^[1-9][0-9]{5,}$/",
      "common": false,
      "en": "Zambia",
      "value": "Zambia"
    }
  ];

  @discourseComputed("formSubmitted")
  submitDisabled() {
    return this.formSubmitted;
  }

  @discourseComputed("currentTelIdx")
  phoneTel() {
    return this.regionData[this.currentTelIdx].iddcode;
  }

  @action
  clickAction(param) {
    this.currentTelIdx = param;
    this.isShowRegion = true;
  }

  @action
  handleClick() {
    this.isShowRegion = !this.isShowRegion;
  }

  @action
  updatePhone(event) {
    this.phoneNumber = event.target.value;
    if (!this.phoneNumber) {
      const element = document.querySelector("#invaild-phone-error");
      element.style.display = "none";
    }
    else if (!this.phoneNumber.match(/^1[23456789]\d{9}$/)) {
      const element = document.querySelector("#invaild-phone-error");
      element.style.display = "block";
    }
    else {
      const element = document.querySelector("#invaild-phone-error");
      element.style.display = "none";
    }
  }

  @action
  createAccount() {
    this.set("flash", "");
    this.set("forceValidationReason", true);

    const validation = [
      this.isNewAccount ? this.usernameValidation : '',
    ].find((v) => v.failed);
    if (validation) {
      const element = validation.element;
      if (element) {
        if (element.tagName === "DIV") {
          if (element.scrollIntoView) {
            element.scrollIntoView();
          }
          element.click();
        } else {
          element.focus();
        }
      }
      return;
    }
    this.set('verificateCodeError', null);
    this.set("forceValidationReason", false);
    this.performAccountCreation();
  }

  // 验证码的校验
  @discourseComputed(
    "accountName",
    "forceValidationReason"
  )
  accountNameValidation(
    accountName,
    forceValidationReason
  ) {
    const failedAttrs = {
      failed: true,
      ok: false,
      element: document.querySelector("#new-account-phone"),
    };
    if (isEmpty(accountName)) {
      return EmberObject.create(
        Object.assign(failedAttrs, {
          message: "verification code required",
          reason: forceValidationReason ? "verification code required" : null,
        })
      );
    }

    if (isNaN(accountName)) {
      return EmberObject.create(
        Object.assign(failedAttrs, {
          reason: "verification code invalid",
        })
      );
    }
    if (!/^\d{4}$/.test(accountName)) {
      return EmberObject.create(
        Object.assign(failedAttrs, {
          reason: "verification code invalid",
        })
      );
    }
    return EmberObject.create({
      ok: true,
      reason: null,
    });
  }

  smsCountdownTimer() {
    if (this.smsCountdown > 0) {
      this.set('isSmsButtonDisabled', true);
      const time = this.smsCountdown - 1;
      this.set('smsCountdown', time);

      setTimeout(this.smsCountdownTimer.bind(this), 1000);
    } else {
      this.set('isSmsButtonDisabled', false);
    }
  }

  // 发送短信的方法
  sendSms() {
    return ajax((`/moyun/sendSsms`), {
      type: "POST",
      headers: {
        'Accept': 'application/json',
      },
      data: {
        mobile: this.phoneNumber,
        idd: this.phoneTel.slice(1),
      }
    }).then((res) => {
      this.set('smsCountdown', 60);
      this.smsCountdownTimer();
    }).catch((error) => {
      console.error('发送短信失败:', error);
    });
  }

  performAccountCreation() {
    const submitData = {
      mobile: this.phoneNumber,
      code: this.phoneTel.slice(1),
    };

    const destinationUrl = this.get("model.authOptions.destination_url");

    if (!isEmpty(destinationUrl)) {
      cookie("destination_url", destinationUrl, { path: "/" });
    }

    this.set("formSubmitted", true);
    return ajax('/moyun/change_mobile', {
      data: submitData,
      type: "POST",
    }).then((result) => {
        // console.log(result, 'result');
        this.set("formSubmitted", false);
        if (result.success) {
          this.dialog.alert({
            title: 'Edited successfully',
            didConfirm: () => {
              window.location.reload();
            },
            didCancel: () => {
              window.location.reload();
            }
          });
        } else {
          this.dialog.alert(result.message);
        }
      });
  }


}
