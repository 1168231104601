/* import __COLOCATED_TEMPLATE__ from './search-result-entry.hbs'; */
import Component from "@ember/component";
import { action } from "@ember/object";
import { logSearchLinkClick } from "discourse/lib/search";
import { modKeysPressed } from "discourse/lib/utilities";

export default Component.extend({
  tagName: "div",
  classNames: ["fps-result"],
  classNameBindings: ["bulkSelectEnabled"],
  attributeBindings: ["role"],
  role: "listitem",

  get isMobile() {
    // 这是一个简单的正则表达式来检测常见的移动端User Agent。
    // 在实际应用中，你可能需要使用更全面的库或服务来检测设备。
    const mobileUserAgent = /Mobi|Android/i.test(navigator.userAgent);
    return mobileUserAgent || window.innerWidth <= 768; // 也可以结合屏幕宽度判断
  },

  @action
  logClick(topicId, event) {
    // Avoid click logging when any modifier keys are pressed.
    if (event && modKeysPressed(event).length > 0) {
      return false;
    }
    if (this.searchLogId && topicId) {
      logSearchLinkClick({
        searchLogId: this.searchLogId,
        searchResultId: topicId,
        searchResultType: "topic",
      });
    }
  },
});
