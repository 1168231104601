import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { getOwner } from "@ember/application";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";
import { and, eq, not, or } from "truth-helpers";
import PluginOutlet from "discourse/components/plugin-outlet";
import DAG from "discourse/lib/dag";
import scrollLock from "discourse/lib/scroll-lock";
import DiscourseURL from "discourse/lib/url";
import { scrollTop } from "discourse/mixins/scroll-top";
import AuthButtons from "./header/auth-buttons";
import Contents from "./header/contents";
import HamburgerDropdownWrapper from "./header/hamburger-dropdown-wrapper";
import Icons from "./header/icons";
import SearchMenuWrapper from "./header/search-menu-wrapper";
import UserMenuWrapper from "./header/user-menu-wrapper";
import { on } from "@ember/modifier";
import Composer from "discourse/models/composer";
const SEARCH_BUTTON_ID = "search-button";
let headerButtons;
resetHeaderButtons();
function resetHeaderButtons() {
    headerButtons = new DAG({
        defaultPosition: {
            before: "auth"
        }
    });
    headerButtons.add("auth");
}
export function headerButtonsDAG() {
    return headerButtons;
}
export function clearExtraHeaderButtons() {
    resetHeaderButtons();
}
export default class GlimmerHeader extends Component {
    @service
    router;
    @service
    search;
    @service
    currentUser;
    @service
    site;
    @service
    appEvents;
    @service
    header;
    @service
    composer;
    @service
    dialog;
    @tracked
    skipSearchContext = this.site.mobileView;
    appEventsListeners = modifier(()=>{
        this.appEvents.on("header:keyboard-trigger", this, this.headerKeyboardTrigger);
        return ()=>{
            this.appEvents.off("header:keyboard-trigger", this, this.headerKeyboardTrigger);
        };
    });
    @action
    headerKeyboardTrigger(msg1) {
        switch(msg1.type){
            case "search":
                this.toggleSearchMenu();
                break;
            case "user":
                this.toggleUserMenu();
                break;
            case "hamburger":
                this.toggleHamburger();
                break;
            case "page-search":
                if (!this.togglePageSearch()) {
                    msg1.event.preventDefault();
                    msg1.event.stopPropagation();
                }
                break;
        }
    }
    @action
    toggleNavigationMenu(override1 = null) {
        if (override1 === "sidebar") {
            return this.toggleSidebar();
        }
        if (override1 === "hamburger") {
            return this.toggleHamburger();
        }
        if (this.args.sidebarEnabled && !this.site.narrowDesktopView) {
            this.toggleSidebar();
        } else {
            this.toggleHamburger();
        }
    }
    @action
    toggleSearchMenu() {
        if (this.site.mobileView) {
            const context1 = this.search.searchContext;
            let params1 = "";
            if (context1) {
                params1 = `?context=${context1.type}&context_id=${context1.id}&skip_context=${this.skipSearchContext}`;
            }
            if (this.router.currentRouteName === "full-page-search") {
                scrollTop();
                document.querySelector(".full-page-search").focus();
                return false;
            } else {
                return DiscourseURL.routeTo("/search" + params1);
            }
        }
        this.search.visible = !this.search.visible;
        if (!this.search.visible) {
            this.search.highlightTerm = "";
            this.search.inTopicContext = false;
            document.getElementById(SEARCH_BUTTON_ID)?.focus();
        }
    }
    @action
    togglePageSearch() {
        this.search.inTopicContext = false;
        let showSearch1 = this.router.currentRouteName.startsWith("topic.");
        // If we're viewing a topic, only intercept search if there are cloaked posts
        if (showSearch1) {
            const container1 = getOwner(this);
            const topic1 = container1.lookup("controller:topic");
            const total1 = topic1.get("model.postStream.stream.length") || 0;
            const chunkSize1 = topic1.get("model.chunk_size") || 0;
            showSearch1 = total1 > chunkSize1 && document.querySelectorAll(".topic-post .cooked, .small-action:not(.time-gap)")?.length < total1;
        }
        if (this.search.visible) {
            this.toggleSearchMenu();
            return showSearch1;
        }
        if (showSearch1) {
            this.search.inTopicContext = true;
            this.toggleSearchMenu();
            return false;
        }
        return true;
    }
    @action
    toggleUserMenu() {
        this.header.userVisible = !this.header.userVisible;
        this.toggleBodyScrolling(this.header.userVisible);
        this.args.animateMenu();
    // this.router.transitionTo("userActivity", this.currentUser);
    }
    @action
    toggleHamburger() {
        if (this.args.sidebarEnabled && !this.site.narrowDesktopView) {
            this.args.toggleSidebar();
            this.args.animateMenu();
        } else {
            this.header.hamburgerVisible = !this.header.hamburgerVisible;
            this.toggleBodyScrolling(this.header.hamburgerVisible);
            this.args.animateMenu();
        }
        var currentPath1 = window.location.href;
        setTimeout(()=>{
            var links1 = document.querySelectorAll(".jump-group");
            if (links1) {
                for(var i1 = 0; i1 < links1.length; i1++){
                    if (links1[i1].href == currentPath1) {
                        links1[i1].classList.add("clicked");
                    }
                }
            }
        }, 300);
    }
    @action
    toggleSidebar() {
        this.args.toggleSidebar();
        this.args.animateMenu();
    }
    @action
    toggleBodyScrolling(bool1) {
        if (this.site.mobileView) {
            scrollLock(bool1);
        }
    }
    @action
    opennewtopic() {
        if (this.currentUser) {
            const filteredGroups1 = this.currentUser.groups.filter((group1)=>group1.name.slice(0, 5) !== "trust");
            if (filteredGroups1.length == 0) {
                this.dialog.alert("You have not joined any groups yet~ / or please first join a certain interest group before posting~");
            } else {
                window.localStorage.removeItem("categoryId");
                if (this.isMobile) {
                    this.composer.openNewTopic({
                        preferDraft: true,
                        category: {
                            id: ""
                        }
                    });
                } else {
                    this.router.transitionTo("newtopic");
                    this.composer.open({
                        action: Composer.CREATE_TOPIC,
                        draftKey: Composer.NEW_TOPIC_KEY,
                        replyflg: true,
                        categoryId: ""
                    });
                }
            }
        } else {
            localStorage.setItem("previousPage", window.location.href);
            // this.router.transitionTo("login");
            this.router.transitionTo("/userlogin");
        }
    }
    get isMobile() {
        // 这是一个简单的正则表达式来检测常见的移动端User Agent。
        // 在实际应用中，你可能需要使用更全面的库或服务来检测设备。
        const mobileUserAgent1 = /Mobi|Android/i.test(navigator.userAgent);
        return mobileUserAgent1 || window.innerWidth <= 768; // 也可以结合屏幕宽度判断
    }
    static{
        template(`
    <header class="d-header" {{this.appEventsListeners}}>
      <div class="wrap">
        <Contents
          @sidebarEnabled={{@sidebarEnabled}}
          @toggleHamburger={{this.toggleHamburger}}
          @showSidebar={{@showSidebar}}
          @toggleNavigationMenu={{this.toggleNavigationMenu}}
        >
          <span class="header-buttons">
            {{#each (headerButtons.resolve) as |entry|}}
              {{#if (and (eq entry.key "auth") (not this.currentUser))}}

                <div class="container-header">
                  {{#if
                    (not
                      (and
                        this.siteSettings.login_required (not this.currentUser)
                      )
                    )
                  }}
                    <Icons
                      @sidebarEnabled={{@sidebarEnabled}}
                      @toggleSearchMenu={{this.toggleSearchMenu}}
                      @toggleHamburger={{this.toggleHamburger}}
                      @toggleUserMenu={{this.toggleUserMenu}}
                      @searchButtonId={{SEARCH_BUTTON_ID}}
                      @toggleNavigationMenu={{this.toggleNavigationMenu}}
                    />
                  {{/if}}
                  {{! <div class="search-icon">
                    <a href="/search">
                      <img src="/images/Search.jpg" alt="Search Icon" />
                    </a>
                  </div> }}
                  <a class="red-button" {{on "click" this.opennewtopic}}>
                    <img src="/images/Add.jpg" alt="Add Icon" />
                    <span>
                      NEW TOPIC
                    </span>
                  </a>

                  <AuthButtons
                    @showCreateAccount={{@showCreateAccount}}
                    @showLogin={{@showLogin}}
                    {{!-- @canSignUp={{@canSignUp}}  --}}
                  />
                </div>
              {{else if entry.value}}
                <entry.value />

              {{/if}}
            {{/each}}
          </span>
          {{! 头部导航 }}
          {{#if
            (not (and this.siteSettings.login_required (not this.currentUser)))
          }}
            {{#if this.isMobile}}
              <Icons
                @sidebarEnabled={{@sidebarEnabled}}
                @toggleSearchMenu={{this.toggleSearchMenu}}
                @toggleHamburger={{this.toggleHamburger}}
                @toggleUserMenu={{this.toggleUserMenu}}
                @searchButtonId={{SEARCH_BUTTON_ID}}
                @toggleNavigationMenu={{this.toggleNavigationMenu}}
              />
            {{/if}}
          {{/if}}
          {{#if this.currentUser}}
            {{#if (not this.isMobile)}}
              <Icons
                @sidebarEnabled={{@sidebarEnabled}}
                @toggleSearchMenu={{this.toggleSearchMenu}}
                @toggleHamburger={{this.toggleHamburger}}
                @toggleUserMenu={{this.toggleUserMenu}}
                @searchButtonId={{SEARCH_BUTTON_ID}}
                @toggleNavigationMenu={{this.toggleNavigationMenu}}
              />
            {{/if}}
          {{/if}}
          {{#if this.search.visible}}
            <SearchMenuWrapper @closeSearchMenu={{this.toggleSearchMenu}} />
          {{else if this.header.hamburgerVisible}}
            <HamburgerDropdownWrapper
              @toggleHamburger={{this.toggleHamburger}}
              @toggleNavigationMenu={{this.toggleNavigationMenu}}
            />
          {{else if this.header.userVisible}}
            <UserMenuWrapper @toggleUserMenu={{this.toggleUserMenu}} />
          {{/if}}

          {{#if
            (and
              (or this.site.mobileView this.site.narrowDesktopView)
              (or this.header.hamburgerVisible this.header.userVisible)
            )
          }}
            <div class="header-cloak"></div>
          {{/if}}
        </Contents>
      </div>
      <PluginOutlet
        @name="after-header"
        @outletArgs={{hash minimized=(globalThis.Boolean this.header.topic)}}
      />
    </header>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
