import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
import { ajax } from "discourse/lib/ajax";


export default class GroupsIndexRoute extends DiscourseRoute {
  queryParams = {
    order: { refreshModel: true, replace: true },
    asc: { refreshModel: true, replace: true },
    filter: { refreshModel: true },
    type: { refreshModel: true, replace: true },
    username: { refreshModel: true },
  };

  titleToken() {
    return I18n.t("groups.index.title");
  }

  async beforeModel() {
    if (!this.currentUser.admin) {
      if (this.currentUser.isModerator) {
        this.router.transitionTo("/g?type=owner");
      } else {
        this.router.transitionTo("/latest");
      }
    }
  }

  model(params) {
    return params;
  }

  setupController(controller, params) {
    controller.loadGroups(params);
  }
}
