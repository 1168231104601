// import { helper } from '@ember/component/helper';

// export function inc([value]) {
//   return parseInt(value) + 1;
// }

// export default helper(inc);

export default function inc(value) {
  return parseInt(value) + 1;
}
