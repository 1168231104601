/* import __COLOCATED_TEMPLATE__ from './xn-forgot-password.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { extractError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import { escapeExpression } from "discourse/lib/utilities";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";

export default class XnForgotPassword extends Component {
  @service siteSettings;

  @tracked
  emailOrUsername = cookie("email") || this.args.model?.emailOrUsername || "";
  @tracked disabled = false;
  @tracked helpSeen = false;
  @tracked offerHelp;
  @tracked flash;
  @tracked verificationCode;
  @tracked phoneNumber;

  // 新增属性来跟踪倒计时
  @tracked smsCountdown = 0; // 倒计时的秒数
  @tracked isSmsButtonDisabled = false; // 控制按钮是否可点击

  get submitDisabled() {
    if (this.disabled) {
      return true;
    } else if (this.siteSettings.hide_email_address_taken) {
      return !this.emailOrUsername.includes("@");
    } else {
      return isEmpty(this.emailOrUsername.trim());
    }
  }


  @action
  smsCountdownTimer() {
    console.log('倒计时开始');
    if (this.smsCountdown > 0) {
      this.isSmsButtonDisabled = true;
      this.smsCountdown--;
      setTimeout(this.smsCountdownTimer.bind(this), 1000);
    } else {
      this.isSmsButtonDisabled = false;
    }
  }

  @action
  // 发送短信的方法
  sendSms() {
    // 这里添加发送短信的逻辑
    return ajax((`/moyun/sendSms`), {
      type: "POST",
      headers: {
        'Accept': 'application/json',
      },
      data: {
        mobile: this.phoneNumber,
      }
    })
      .then((res) => {
        // console.log(res, '发送短信成功');
        // 假设短信已发送，开始60秒的倒计时
        this.smsCountdown = 60;
        this.smsCountdownTimer();

      })
      .catch((error) => {
        // 短信发送失败，处理错误
        console.error('发送短信失败:', error);

      });
  }

  @action
  updateEmailOrUsername(event) {
    this.phoneNumber = event.target.value;
    if (!this.phoneNumber) {
      const element = document.querySelector("#invaild-phone-error");
      element.style.display = "none";
    }
    else if (!this.phoneNumber.match(/^1[3456789]\d{9}$/)) {
      const element = document.querySelector("#invaild-phone-error");
      element.style.display = "block";
    }
    else {
      const element = document.querySelector("#invaild-phone-error");
      element.style.display = "none";
    }
  }
  @action
  updateVerificate(event) {
    this.verificationCode = event.target.value;
  }

  // @action
  // async sendPasswordResetEmail() {
  //   try {
  //     const result = await ajax("/session/forgot_password.json", {
  //       data: { login: 'xiaonan' },
  //       type: "POST",
  //     });

  //     if (result.success) {
  //       this.errorMessage = null;
  //       this.resetEmailSent = I18n.t(
  //         "user.confirm_access.password_reset_email_sent"
  //       );
  //     } else {
  //       this.errorMessage = I18n.t(
  //         "user.confirm_access.cannot_send_password_reset_email"
  //       );
  //     }
  //   } catch (e) {
  //     this.errorMessage = extractError(
  //       e,
  //       I18n.t("user.confirm_access.cannot_send_password_reset_email")
  //     );
  //   }
  // }



  @action
  help() {
    this.offerHelp = I18n.t("forgot_password.help", { basePath: getURL("") });
    this.helpSeen = true;
  }

  @action
  async resetPassword() {
    if (this.submitDisabled) {
      return false;
    }

    this.disabled = true;
    this.flash = null;

    try {
      const data = await ajax("/session/forgot_password", {
        data: { login: this.emailOrUsername.trim() },
        type: "POST",
      });

      const emailOrUsername = escapeExpression(this.emailOrUsername);

      let key = "forgot_password.complete";
      key += emailOrUsername.match(/@/) ? "_email" : "_username";

      if (data.user_found === false) {
        key += "_not_found";

        this.flash = htmlSafe(
          I18n.t(key, {
            email: emailOrUsername,
            username: emailOrUsername,
          })
        );
      } else {
        key += data.user_found ? "_found" : "";

        this.emailOrUsername = "";
        this.offerHelp = I18n.t(key, {
          email: emailOrUsername,
          username: emailOrUsername,
        });

        this.helpSeen = !data.user_found;
      }
    } catch (error) {
      this.flash = extractError(error);
    } finally {
      this.disabled = false;
    }
  }
}
