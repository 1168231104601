/* import __COLOCATED_TEMPLATE__ from './password-modal.hbs'; */
import EmberObject,{ action} from "@ember/object";
import { service } from "@ember/service";
import Component from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import { ajax } from "discourse/lib/ajax";
export default class Passwordmodal extends Component {
  @service dialog;
  OldPassword=null
  NewPassword=null
  ConfirmPassword=null
  saveloading=false
  @action
  save(){
    if(this.OldPassword==null ||this.NewPassword==null ||this.ConfirmPassword==null){
      this.dialog.alert(
       'The password cannot be empty.'
      );
      return
    }
    if(this.ConfirmPassword !== this.NewPassword){
      this.dialog.alert(
        'The new password and the confirmation password do not match.'
       );
       return
    }
     ajax(`/moyun/reset_pwd`,{type:"POST", data:{old_pwd:this.OldPassword,password:this.NewPassword}}).then(res=>{
      this.dialog.alert(
        res.message
       );
    })
  }
  @action
  reset(){
    this.set('OldPassword',null)
    this.set('NewPassword',null)
    this.set('ConfirmPassword',null)
  }
  @discourseComputed("OldPassword")
  OldPasswordfun(){
  //  return this.passwordcodeRegularexpression(this.OldPassword)
  }
  @discourseComputed("NewPassword")
  NewPasswordfun(){
   return this.passwordcodeRegularexpression(this.NewPassword)
  }
  @discourseComputed("ConfirmPassword")
  ConfirmPasswordfun(){
   return this.passwordcodeRegularexpression(this.ConfirmPassword)
  }
  passwordcodeRegularexpression(value){
    if(!value) return
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if(!regex.test(value)){
      return  EmberObject.create({
      failed: true,
      reason: 'Your password must be at least 8 characters long and include at least one letter, one number, and one special character. Please try again',
    })
    }else{
      return null
    }

  }
}
