import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { and, not } from "truth-helpers";
import DButton from "discourse/components/d-button";
export default class AuthButtons extends Component {
    @service
    header;
    static{
        template(`
    <span class="auth-buttons">
      {{#if (and @canSignUp (not this.header.topic))}}
        <DButton
          class="btn-primary btn-small sign-up-button"
          @action={{@showCreateAccount}}
          @label="sign_up"
        />
      {{/if}}

      <DButton
        style="background-color: rgba(0, 0, 0, 0);"
        class="btn-primary btn-small sign-up-button login-text snowBtn"
        @action={{@showLogin}}
        @label="log_in"
        {{! @icon="user" }}
      />
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
