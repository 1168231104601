/* import __COLOCATED_TEMPLATE__ from './username-preference.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { empty, or } from "@ember/object/computed";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { setting } from "discourse/lib/computed";
import DiscourseURL, { userPath } from "discourse/lib/url";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import { ajax } from "jquery";

export default class UsernamePreference extends Component {
  @service siteSettings;
  @service dialog;

  @tracked editing = false;
  @tracked newUsername = this.args.user.username;
  @tracked errorMessage = null;
  @tracked saving = false;
  @tracked taken = false;

  @setting("max_username_length") maxLength;
  @setting("min_username_length") minLength;
  @empty("newUsername") newUsernameEmpty;

  @or("saving", "newUsernameEmpty", "taken", "unchanged", "errorMessage")
  saveDisabled;

  get unchanged() {
    return this.newUsername === this.args.user.name;
  }

  get saveButtonText() {
    return this.saving ? I18n.t("saving") : I18n.t("user.change");
  }

  @action
  toggleEditing() {
    this.editing = !this.editing;

    this.newUsername = this.args.user.name;
    this.errorMessage = null;
    this.saving = false;
    this.taken = false;
  }

  @action
  async onInput(event) {
    this.newUsername = event.target.value;
    this.taken = false;
    this.errorMessage = null;

    if (isEmpty(this.newUsername)) {
      return;
    }

    if (this.newUsername === this.args.user.name) {
      return;
    }

    if (this.newUsername.length < 2) {
      this.errorMessage = I18n.t("user.name.too_short");
      return;
    }

    const regex = /^[a-zA-Z0-9 ]{2,}$/;
    let res = regex.test(this.newUsername);
    if (!res) {
      this.errorMessage = 'Cannot use special characters'
      return;
    }

    // const result = await User.checkUsername(
    //   this.newUsername,
    //   undefined,
    //   this.args.user.id
    // );

    // if (result.errors) {
    //   this.errorMessage = result.errors.join(" ");
    // } else if (result.available === false) {
    //   this.taken = true;
    // }
  }

  @action
  changeUsername() {
    return this.dialog.yesNoConfirm({
      title: I18n.t("user.change_username.confirm"),
      didConfirm: async () => {
        this.saving = true;

        ajax(`/users/${this.args.user.username}.json`,{
          type: "PUT",
          data: { name: this.newUsername }
        }).then( res=>{
          if (res.success) {
            window.location.reload();
          } else {
            this.dialog.alert(
              res.message
            );
            this.saving = false;
          }
        }).catch(e=>{
          this.saving = false;
          console.log('e',e);
        }).finally(()=>{
          this.saving = false;
        })
        // try {
        //   // await this.args.user.changeUsername(this.newUsername);
        //   await ajax(`/users/${this.args.user.username}.json`,{
        //     type: "PUT",
        //     data: { name: this.newUsername }
        //   });
        //   // window.location.reload();
        //   DiscourseURL.redirectTo(
        //     userPath(this.args.user.username.toLowerCase() + "/preferences")
        //   );
        // } catch (e) {
        //   popupAjaxError(e);
        // } finally {
        //   this.saving = false;
        // }
      },
    });
  }
}
