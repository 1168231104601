import { ajax } from "discourse/lib/ajax";
import { service } from "@ember/service";
import Category from "discourse/models/category";
import TopicList from "discourse/models/topic-list";
import {
  filterQueryParams,
  findTopicList,
} from "discourse/routes/build-topic-route";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";

export default DiscourseRoute.extend({
  store: service(),
  historyStore: service(),
  site: service(),

  titleToken() {
    return I18n.t(`groups.topics`);
  },

  async _retrieveTopicList(category, transition, modelParams) {
    // console.log('category11', category);
    const findOpts = filterQueryParams(modelParams, this.routeConfig);
    const extras = { cached: this.historyStore.isPoppedState };
    let listFilter = `c/${Category.slugFor(category)}/${category.id}/l/latest`;
    const topicList = await findTopicList(
      this.store,
      this.topicTrackingState,
      listFilter,
      findOpts,
      extras
    );
    TopicList.hideUniformCategory(topicList, category);
    return topicList;
  },

  async model(params, transition) {
    let group_cate_id = this.modelFor("group").get("full_name");
    let res = await ajax('/categories');
    let currentCate = res.category_list.categories.find(item => item.name == group_cate_id);
    let cate_path = currentCate.slug + '/' + currentCate.id;
    const category = Category.findBySlugPathWithID(cate_path);
    const topicListPromise = this._retrieveTopicList(
      category,
      transition,
      params
    );
    return await topicListPromise
  },
});
