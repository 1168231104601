import { htmlSafe } from "@ember/template";
import {
  APPROVED,
  DELETED,
  IGNORED,
  PENDING,
  REJECTED,
} from "discourse/models/reviewable";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";

function dataFor(status, type) {
  switch (status) {
    case PENDING:
      return { name: "pending" };
    case APPROVED:
      switch (type) {
        case "ReviewableQueuedPost":
          return {
            icon: "check",
            name: "approved_post",
            cssClass: "approved",
          };
        case "ReviewableUser":
          return {
            icon: "check",
            name: "approved_user",
            cssClass: "approved",
          };
        default:
          return {
            icon: "check",
            name: "approved_flag",
            cssClass: "approved",
          };
      }
    case REJECTED:
      switch (type) {
        case "ReviewableQueuedPost":
          return {
            icon: "times",
            name: "rejected_post",
            cssClass: "rejected",
          };
        case "ReviewableUser":
          return {
            icon: "times",
            name: "rejected_user",
            cssClass: "rejected",
          };
        default:
          return {
            icon: "times",
            name: "rejected_flag",
            cssClass: "rejected",
          };
      }
    case IGNORED:
      return {
        icon: "external-link-alt",
        name: "ignored",
      };
    case DELETED:
      return { icon: "trash-alt", name: "deleted" };
}

if(status==5){
  return { icon: "times",cssClass: "rejected", name: "block"}; //包含敏感话题，不通过
}else if(status==6){
  return { icon: "check",cssClass: "approved", name: "pass" }; //不包含敏感话题，通过
}else if(status==7){
  return { icon: "exclamation-triangle", cssClass: "review",name: "review" }; //需要人工复检
}
}

export function htmlStatus(status, type, res) {
  let data = dataFor(status, type);
  if (!data) {
    return;
  }

  let icon = data.icon ? iconHTML(data.icon) : "";
 const str= res?.details.map(item=>item.label).join('、')
 let newSpan=''
if(str) {
  const firstChar = `【${str.charAt(0)}`;
  const lastChar = `${str.charAt(str.length - 1)}】`;
  const middleSection = str.slice(1, -1);
 newSpan = `${firstChar}${middleSection}${lastChar}`;

}
  return `
    <span class="${data.cssClass || data.name}">
      ${icon}
      ${I18n.t("review.statuses." + data.name + ".title")}
      ${newSpan}
    </span>
  `;
}

export default function (status, type, res) {
  return htmlSafe(htmlStatus(status, type, res));
}
