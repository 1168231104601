/* import __COLOCATED_TEMPLATE__ from './achievements.hbs'; */

import Component from '@glimmer/component';


export default class AchievementsComponent extends Component {
    get items() {
        const medalList = [
            {
                name: 'vaultnine',
                details: 'has earned the badge Schmoozer'
            },
            {
                name: 'john_doe',
                details: 'has earned the badge Schmoozer'
            },
            {
                name: 'wsigparis',
                details: 'has earned the badge Schmoozer'
            },
            {
                name: 'DPLondon24',
                details: 'has earned the badge Schmoozer'
            },
            {
                name: 'Sandykaufmann',
                details: 'has earned the badge Schmoozer'
            },

        ]

        return medalList;
    }

    get isMobile() {
        // 这是一个简单的正则表达式来检测常见的移动端User Agent。
        // 在实际应用中，你可能需要使用更全面的库或服务来检测设备。
        const mobileUserAgent = /Mobi|Android/i.test(navigator.userAgent);
        return mobileUserAgent || window.innerWidth <= 768; // 也可以结合屏幕宽度判断
    }
}


