/* import __COLOCATED_TEMPLATE__ from './creaters.hbs'; */

import { action } from '@ember/object';
import { th } from '@faker-js/faker';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ajax } from "discourse/lib/ajax";
import { userPath } from "discourse/lib/url";

export default class CreatersComponent extends Component {
  @tracked activeIndex = '0';
  @tracked leaderboard = null;
  @tracked leaderboardgroup = null;
  @tracked period="all";
  @tracked leaderboardid=window.location.hostname=="localhost"?7:2
  collection=["all",  "weekly", "monthly","yearly"]
  constructor() {
    super(...arguments);
    this.getleaderboardlist()
    this.getleaderboardgrouplist()
  }
  @action
  userPath(username) {
    return userPath(username);
  }

  @action
  async selectTitle(index) {
    this.activeIndex = index;
  const domainName=  window.location.hostname
    if (this.activeIndex == 0) {
      this.leaderboardid=domainName=="localhost"?7:2
      this.getleaderboardlist()
    } else if (this.activeIndex == 1) {
      this.leaderboardid=domainName=="localhost"?1:4
      this.getleaderboardlist()
    }
    else if(this.activeIndex==2){
      this.leaderboardid=''
      this.getleaderboardlist()
    }

    setTimeout(()=>{
      let data=this.leaderboard
      if( data.length > 10){
        let showleaderboard= document.querySelector('.showleaderboard')
       showleaderboard.style.display= 'block';
      }
    },500)
  }
  @action
  async changePeriod(period) {
    this.period=period
    this.getleaderboardlist()
  }
  @action
  async getleaderboardlist() {
    await ajax(`/leaderboard/${this.leaderboardid}?period=${this.period}`)
      .then((response) => {
            if( response.users.length > 10){
              this.leaderboard = response.users.slice(0, 10);
            }else{
              this.leaderboard = response.users
            }
      })
  }

  @action
  async getleaderboardgrouplist() {
    await ajax(`/moyun/leaderboard_for_group`,{
      type: "POST",
    })
      .then((response) => {
        if(response.users){
          let newArray = response.users.filter(item => item.username !== "General");
              if( newArray.length > 10){
                this.leaderboardgroup = newArray.slice(0, 10);
              }else{
                this.leaderboardgroup = newArray
              }
        }

      })
  }

}
