import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { addExtraUserClasses } from "discourse/helpers/user-avatar";
import { avatarImg } from "discourse-common/lib/avatar-utils";
import { registerRawHelper } from "discourse-common/lib/helpers";
import { ajax } from "discourse/lib/ajax";


registerRawHelper("moyunName", moyunName);
export default function moyunName(openids) {


  let getmsg =  localStorage.getItem(`${openids}`);
  if (getmsg) {
    let userMsg = JSON.parse(getmsg);
    // console.log('userMsg',userMsg);
    return `${userMsg.nickname}`;
  } else {
    ajax("/moyun/s_other_profile",{
      type:"POST",
      data: { openids },
      // data: { openids: '786ea0000bd0000009829f0e7242, 4ad5d0000bd0000009829cf2c6f4, d68960000bd0000009829c76db6b'},
    }).then((res) => {
      // console.log('res',res);
      localStorage.setItem(res.data[0].openid, JSON.stringify(res.data[0]));
      return `${res.data[0].nickname}`;
    })
  }
}
