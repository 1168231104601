import Controller from "@ember/controller";
import discourseComputed from "discourse-common/utils/decorators";
import { htmlSafe } from "@ember/template";


function badgeKey(badge) {
  let pos = badge.get("badge_grouping.position");
  let type = badge.get("badge_type_id");
  let name = badge.get("name");
  return ("000" + pos).slice(-4) + (10 - type) + name;
}

export default Controller.extend({
  mybgList : [
    {
      title:"Platinum  User",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:461,
      picture:"/images/mybg_0.png",
    },
    {
      title:"Crazy Scientist",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:1000,
      picture:"/images/mybg_1.png",
    },
    {
      title:"Profile Age",
      descrip:"Helped us beta testing new awesome features",
      total:342,
      picture:"/images/mybg_2.png",
    },
    {
      title:"Gold User",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:461,
      picture:"/images/mybg_3.png",
    },
    {
      title:"Sliver User",
      descrip:"Helped us beta testing new awesome features",
      total:461,
      picture:"/images/mybg_4.png",
    },
    {
      title:"Forum Traveller",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:882,
      picture:"/images/mybg_5.png",
    },
    {
      title:"Platinum  User",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:461,
      picture:"/images/mybg_0.png",
    },
    {
      title:"Crazy Scientist",
      descrip:"Helped us beta testing new awesome features",
      total:1000,
      picture:"/images/mybg_1.png",
    },
    {
      title:"Profile Age",
      descrip:"Helped us beta testing new awesome features",
      total:461,
      picture:"/images/mybg_2.png",
    },
    {
      title:"Gold User",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:342,
      picture:"/images/mybg_3.png",
    },
    {
      title:"Sliver User",
      descrip:"Has posted more than 1000 pasts on their profile",
      total:461,
      picture:"/images/mybg_4.png",
    },
    {
      title:"Forum Traveller",
      descrip:"Helped us beta testing new awesome features",
      total:882,
      picture:"/images/mybg_5.png",
    },
  ],

  styleProp(total) {
    let width = total / 10;
    return htmlSafe(`width: ${width}%;`);
  },

  @discourseComputed("model")
  badgeGroups(model) {
    let sorted = model.sort((a, b) => badgeKey(a).localeCompare(badgeKey(b)));
    let grouped = [];
    let group = [];
    let groupId;

    sorted.forEach(function (badge) {
      if (groupId !== badge.badge_grouping_id) {
        if (group && group.length > 0) {
          grouped.push({
            badges: group,
            badgeGrouping: group[0].badge_grouping,
          });
        }
        group = [];
        groupId = badge.badge_grouping_id;
      }
      group.push(badge);
    });

    if (group && group.length > 0) {
      grouped.push({ badges: group, badgeGrouping: group[0].badge_grouping });
    }

    return grouped;
  },
});
