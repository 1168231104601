
import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from '@ember/object';
import Composer from "discourse/models/composer";
import discourseComputed, {
  bind,
  observes,
} from "discourse-common/utils/decorators";
import { ajax } from 'discourse/lib/ajax';
export default class NewtopicController extends Controller {
  @service router;
  @service composer;
  @service currentUser;
  isModerator=null
  init() {
    super.init(...arguments);
    let categoryIdId = JSON.parse(localStorage.getItem('categoryId'));
    setTimeout(() => {
      this.composer.open({
        action: Composer.CREATE_TOPIC,
        draftKey: Composer.NEW_TOPIC_KEY,
        replyflg: true,
        categoryId:categoryIdId
      });
    }, 500)
    this.getmoderator()
  }
  getmoderator(){
    if(this.currentUser){
        if(this.currentUser.admin || this.currentUser.isModerator){
          this.set('isModerator',true)
        }else{
          this.set('isModerator',false)
        }
    }

  }
}





