/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { on } from "@ember/modifier";
import { ajax } from "discourse/lib/ajax";
import Composer from "discourse/models/composer";

export default class Layout extends Component {
  @tracked mysideBar = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  @service composer;
  @service currentUser;
  @service router;
  @service dialog;

  @tracked homeGroupList = [];

  constructor() {
    super(...arguments);
    this.getGroupList();
  }

  @action
  opennewtopic() {
    window.localStorage.removeItem("categoryId");
    if (this.currentUser) {
      const filteredGroups = this.currentUser.groups.filter(
        (group) => group.name.slice(0, 5) !== "trust"
      );
      if (filteredGroups.length == 0) {
        this.dialog.alert(
          "You have not joined any groups yet~ / or please first join a certain interest group before posting~"
        );
      } else {
        if (this.isMobile) {
          this.composer.openNewTopic({
            preferDraft: true,
            category: {
              id: "",
            },
          });
        } else {
          this.router.transitionTo("newtopic");
          this.composer.open({
            action: Composer.CREATE_TOPIC,
            draftKey: Composer.NEW_TOPIC_KEY,
            replyflg: true,
            categoryId: "",
          });
        }
      }
    } else {
      localStorage.setItem('previousPage', window.location.href)
      this.router.transitionTo("/userlogin");
    }
  }

  get isMobile() {
    const mobileUserAgent = /Mobi|Android/i.test(navigator.userAgent);
    return mobileUserAgent || window.innerWidth <= 768;
  }

  @action
  async getGroupList() {
    const result = await ajax(("/groups"), {
      type: "GET",
    })
    let myGroupList = ["Shanghai", "Publicwelfare", "Travel", "Sports", "Hospital", "Italian"]; //包含
    let newList = [];
    result.groups.forEach(item => {
      // if (myGroupList.includes(item.name)) {
      if( item.automatic == false && item.name != 'General' && item.sort_order > 0) {
        newList.push(item);
      }
    })
    newList.sort((pre, nxt) => nxt.sort_order - pre.sort_order);
    this.homeGroupList = newList;
    // this.homeGroupList.sort((pre, nxt) => pre.sort_order - nxt.sort_order);
  }
}
