/* import __COLOCATED_TEMPLATE__ from './category-title-link.hbs'; */
import Component from "@ember/component";
export default Component.extend({
  tagName: "h3",
  // icon name defined here so it can be easily overridden in theme components
  lockIcon: "lock",
  newUrl: "",

  init() {
    this._super(...arguments);
    if (this.category && this.category.url) {
      this.newUrl = this.category.url.replace(/\/\d+$/, '/edit/general');
    }
  }
});
