/* import __COLOCATED_TEMPLATE__ from './community-champion.hbs'; */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from "@ember/template";
import { autoUpdatingRelativeAge } from "discourse/lib/formatter";
import { service } from "@ember/service";

export default class CommunityChampionComponent extends Component {
  @tracked activeIndex = '1';

  get isShowLeaveBtn() {
    let owners = JSON.parse(localStorage.getItem('isowners'));
    let cname = this.args.model.full_name;
    let topicowner= owners.some(item=>item.full_name==cname)
    return !topicowner;
  }

  get descs() {
    const descList = [
      {
        type: 'Topics',
        count: this.args.moderatorMes.topic_count
      },
      {
        type: 'Replies',
        count: this.args.moderatorMes.post_count
      },
      {
        type: 'Points',
        count: this.args.moderatorAbout?.gamification_score
      }
    ];

    return descList;
  }

  get instr() {
    const instrList = [
      {
        headline: 'Member since',
        text: this.boundDate(this.args.moderatorAbout.created_at)
      },
      // {
      //   headline: 'Industry',
      //   text: 'Education, Government'
      // },
      {
        headline: 'Location',
        text: this.args.moderatorAbout.location
      },
      {
        headline: 'Linkedin Profile',
        text: this.args.moderatorAbout.website
      },
    ];

    return instrList;
  }

  boundDate(dt) {
    return htmlSafe(
      autoUpdatingRelativeAge(new Date(dt), {
        format: "medium",
        title: true,
      })
    );
  }

  @action
  selectTitle(index) {
    this.activeIndex = index;
  }
}
