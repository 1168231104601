import RestAdapter from "discourse/adapters/rest";
import { ajax } from "discourse/lib/ajax";
import PreloadStore from "discourse/lib/preload-store";
import Topic from "discourse/models/topic";

export default RestAdapter.extend({
  find(store, type, { filter, params }) {
    return PreloadStore.getAndRemove("/latest.json?order=created", () => {
      let url
      if(filter=='top'){
         url = `/${filter}.json`;
      }else{
       url = `/${filter}.json?order=created`;
      }


      if (params) {
        const urlSearchParams = new URLSearchParams();

        for (const [key, value] of Object.entries(params)) {
          if (typeof value === "undefined") {
            continue;
          }

          if (Array.isArray(value)) {
            for (const arrayValue of value) {
              urlSearchParams.append(`${key}[]`, arrayValue);
            }
          } else {
            urlSearchParams.set(key, value);
          }
        }

        const queryString = urlSearchParams.toString();

        if (queryString) {
          url += `?${queryString}`;
        }
      }
      return ajax(url);
    }).then((result) => {

      setTimeout(()=>{
        if(result.topic_list.topics){
          const button = document.getElementById("load-more-button");
    if (!button) return
    const aTag = button.querySelector("a");
      const mobileUserAgent = /Mobi|Android/i.test(navigator.userAgent);
      if (mobileUserAgent || window.innerWidth <= 768) {

        if (result.topic_list.topics.length < 10) {
          button.style.display = "none";
        }else{
          button.style.display = "block";
        }
      } else {
        if (result.topic_list.topics.length < 10) {
          aTag.textContent = "No more topics";
        }else{
          aTag.textContent = "Show more topic";
        }
      }
    }
      },1000)

      result.filter = filter;
      result.params = params;
      return result;
    });
  },

  async applyTransformations(results) {
    for (const topicList of results) {
      await Topic.applyTransformations(topicList.topics);
    }
  },
});
