import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class BreadcrumbsService extends Service {
  @tracked items = [];

  // 添加面包屑项
  addItem(label, routeName, url,models = []) {
    this.items = [...this.items, { label, routeName, url,models }];
  }

  // 清除面包屑
  clear() {
    this.items = [];
  }

  // 设置面包屑项（将会替代现有项目）
  setItems(items) {
    this.clear();
    items.forEach(item => this.addItem(item.label, item.routeName,item.url, item.models));
  }
}
