import DiscourseRoute from "./discourse";

export default DiscourseRoute.extend({
    activate() {
        this._super(...arguments); // 确保调用父类的activate方法
        this.updateHeaderBackground(true); // 激活时设置为透明
    },

    deactivate() {
        this._super(...arguments); // 确保调用父类的deactivate方法
        this.updateHeaderBackground(false); // 停用时恢复默认背景
    },

    updateHeaderBackground(isTransparent) {
        const header = document.querySelector(".d-header");
        if (header) {
            if (isTransparent) {
                header.style.background = 'transparent';
            } else {
                header.style.background = 'var(--header_background)';
            }
        }
    },

    renderTemplate() {
        this._super(...arguments); // 确保调用父类的renderTemplate方法
        this.renderTemplate('weekly-quiz', {
            into: 'application', outline: 'main'
        });
    }
});
