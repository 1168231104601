import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import EmberObject, { action } from "@ember/object";
export default EmberObject.extend({
  jsontopic:null,
  init() {
    this._super(...arguments);
      if (this.topic.liked) {
        this.topic.likeclass = 'liketrue';
      } else {
        this.topic.likeclass = '';
      }
  },


})
