import DiscourseRoute from "./discourse";
import { ajax } from "discourse/lib/ajax";

export default DiscourseRoute.extend({

  async model() {
    const result = await ajax(("/categories"), {
      type: "GET",
    })
    console.log(result,'result');
    // console.log('categories', result.category_list.categories);
    // let myGroupList =['Shanghai',"NYC Chapter: In-Person User Group","D-A-CH Community Gruppe","Radarly User Group - Coming Soon","UPLIFT: Connecting LGBTQ+ and Allied Professionals","Australia Chapter: User Group","NYC Chapter: In-Person User Group","Boolean Wizards Academy Group"];
    let myGroupList = ["Staff","General","Site Feedback"];
    let newList = [];
    result.category_list.categories.forEach(item => {
      if (!myGroupList.includes(item.name)) {
        newList.push(item);
      }
    })
    result.category_list.categories = newList;
    // console.log('newList', newList);
    return result;
  },

  renderTemplate() {
    this.renderTemplate('user-groups', {
      into: 'application', outline: 'main'
    })
  }

})
