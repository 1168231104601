import { ajax } from "discourse/lib/ajax";
export async function  translation(res) {
    let data={
      content:res.content,
      lang:res.lang
    }
  let result= await ajax(
      `/moyun/gpt`,
      {
        type: "POST",
        data,
        timeout: 60000,
      }
    )
    return result.result.data.outputs.second_translation
}
