/* import __COLOCATED_TEMPLATE__ from './review-modal.hbs'; */
import EmberObject,{ action} from "@ember/object";
import { service } from "@ember/service";
import Component from "@ember/component";
import { tracked } from "@glimmer/tracking";
import { translation } from "discourse/models/translation";
import { ajax } from "discourse/lib/ajax";
import { htmlSafe } from '@ember/template';
export default class Reviewmodal extends Component {
 @tracked data=this.model.item
 @tracked TranslationType=true
 @tracked clickTimeout=true
 @tracked translationtitlaloading=false
 @service dialog;
 @tracked failedloding=false
 @tracked approvedloding=false
 @tracked content=this.data.cooked
 @tracked title=this.data.title
 init() {
  super.init(...arguments);
  console.log(this.data);
}
@action
translationfun(){
    setTimeout(async () => {
      if(this.clickTimeout){
        this.gettranslation()
      }
    }, 500);
}
async gettranslation(){
  this.translationtitlaloading=true
  let Type = this.TranslationType ? '简体中文' :'英式英文';
   let blurbs=  await translation({
      content: this.data.content,
      lang: Type
    });
    let title=  await translation({
      content: this.data.title,
      lang: Type
    });
    if(blurbs && title){
      this.translationtitlaloading=false
      this.content=blurbs
      this.title=title
    }


}
@action
translationswitch(){
  this.clickTimeout=false
  this.TranslationType=!this.TranslationType
  setTimeout(()=>{
    this.clickTimeout=true
  },1000)
}
conten(text){
  if(text){
  return htmlSafe(text)
  }

}

  // 审核
  @action
failed(){
  this.failedloding=true
  this.reviewfun('failed')
}
@action
approved(){
  this.approvedloding=true
  this.reviewfun('approved')
}
reviewfun(val){
   ajax(`moyun/batch_topics`, {  type: "POST",data:{
    operation:val,
    ids:[this.data.id],
    type:this.model.type
   }}).then(res=>{
    this.dialog.alert(val=='approved'?"Approval Successful":"Cancellation of review successful");
    this.model.reviewthis._search()
    this.closeModal()
   }).catch(err=>{
   this.dialog.alert(err.errorThrown);
   this.model.reviewthis._search()
   this.closeModal()
   }).finally(()=>{
    this.failedloding=false
    this.approvedloding=false
   })
}
}
