import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { fn } from "@ember/helper";
import { action } from "@ember/object";
import { inject as service } from "@ember/service";
import { and, not, or } from "truth-helpers";
import DButton from "discourse/components/d-button";
import DropdownMenu from "discourse/components/dropdown-menu";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import DMenu from "float-kit/components/d-menu";
import Category from "discourse/models/category";
import Group from "discourse/models/group";
import { getOwner } from "@ember/owner";
import { ajax } from "discourse/lib/ajax";
import { tracked } from "@glimmer/tracking";
export default class TopicAdminMenu extends Component {
    @service
    adminTopicMenuButtons;
    @service
    currentUser;
    @tracked
    isCurrentGroupOwner = false;
    constructor(){
        super(...arguments);
        this.getOwnerInfo();
    }
    @action
    onRegisterApi(api1) {
        this.dMenu = api1;
    }
    @action
    async onButtonAction(buttonAction1) {
        await this.dMenu.close();
        this.args[buttonAction1]?.();
    }
    @action
    async onExtraButtonAction(buttonAction1) {
        await this.dMenu.close();
        buttonAction1?.();
    }
    @action
    async getOwnerInfo() {
        if (!this.args.topic.get("category_id")) return;
        let owners1 = JSON.parse(localStorage.getItem("isowners"));
        let current_atr_cate1 = this.args.topic.get("category_id");
        let categoryname1 = Category.findById(current_atr_cate1).name;
        this.isCurrentGroupOwner = owners1.some((item1)=>item1.full_name == categoryname1);
    // console.log('1111',this.args.topic.get("details"));
    // let cateName = Category.findById(current_atr_cate).name;
    // let groups = await Group.findAll();
    // let res = groups.find((item) => item.full_name == cateName);
    // let params = {
    //   offset: 0,
    //   order: null,
    //   asc: true,
    //   filter: null,
    // };
    // const response = await Group.loadMembers(res.name, params);
    // this.isCurrentGroupOwner = response.owners.some(
    //   (item) => item.id == this.currentUser.id
    // );
    }
    get extraButtons() {
        return this.adminTopicMenuButtons.callbacks.map((callback1)=>{
            return callback1(this.args.topic);
        }).filter(Boolean);
    }
    get details() {
        return this.args.topic.get("details");
    }
    get isPrivateMessage() {
        return this.args.topic.get("isPrivateMessage");
    }
    get featured() {
        return (!!this.args.topic.get("pinned_at") || this.args.topic.get("isBanner"));
    }
    get visible() {
        return this.args.topic.get("visible");
    }
    get canDelete() {
        return this.details.get("can_delete");
    }
    get canRecover() {
        return this.details.get("can_recover");
    }
    get archived() {
        return this.args.topic.get("archived");
    }
    get topicModerationHistoryUrl() {
        return getURL(`/review?topic_id=${this.args.topic.id}&status=all`);
    }
    get showAdminButton() {
        return (this.currentUser?.canManageTopic || this.details?.can_archive_topic || this.details?.can_close_topic || this.isCurrentGroupOwner);
    }
    static{
        template(`
    {{#if (or this.currentUser.admin this.isCurrentGroupOwner)}}
      {{#if this.showAdminButton}}
        <span class="topic-admin-menu-button-container">
          <span class="topic-admin-menu-button">
            <DMenu
              @identifier="topic-admin-menu"
              @onRegisterApi={{this.onRegisterApi}}
              @modalForMobile={{true}}
              @autofocus={{true}}
              @triggerClass="btn-default btn-icon toggle-admin-menu"
            >
              <:trigger>
                {{icon "wrench"}}
              </:trigger>
              <:content>
                <DropdownMenu as |dropdown|>
                  {{#if
                    (or
                      this.currentUser.canManageTopic
                      this.details.can_split_merge_topic
                    )
                  }}
                    <dropdown.item class="topic-admin-multi-select">
                      <DButton
                        class="btn-transparent"
                        @label="topic.actions.multi_select"
                        @action={{fn this.onButtonAction "toggleMultiSelect"}}
                        @icon="tasks"
                      />
                    </dropdown.item>
                  {{/if}}

                  {{#if
                    (or
                      this.currentUser.canManageTopic
                      this.details.can_moderate_category
                    )
                  }}
                    {{#if this.canDelete}}
                      <dropdown.item class="topic-admin-delete">
                        <DButton
                          @label="topic.actions.delete"
                          @action={{fn this.onButtonAction "deleteTopic"}}
                          @icon="far-trash-alt"
                          class="popup-menu-btn-danger btn-danger btn-transparent"
                        />
                      </dropdown.item>
                    {{else if this.canRecover}}
                      <dropdown.item class="topic-admin-recover">
                        <DButton
                          class="btn-transparent"
                          @label="topic.actions.recover"
                          @action={{fn this.onButtonAction "recoverTopic"}}
                          @icon="undo"
                        />
                      </dropdown.item>
                    {{/if}}
                  {{/if}}

                  {{!-- {{#if this.details.can_close_topic}} --}}
                    <dropdown.item
                      class={{if
                        @topic.closed
                        "topic-admin-open"
                        "topic-admin-close"
                      }}
                    >
                      <DButton
                        class="btn-transparent"
                        @label={{if
                          @topic.closed
                          "topic.actions.open"
                          "topic.actions.close"
                        }}
                        @action={{fn this.onButtonAction "toggleClosed"}}
                        @icon={{if @topic.closed "unlock" "lock"}}
                      />
                    </dropdown.item>
                  {{!-- {{/if}} --}}

                  {{!-- {{#if
                    (and
                      this.details.can_pin_unpin_topic
                      (not this.isPrivateMessage)
                      (or this.visible this.featured)
                    )
                  }} --}}
                  {{#if
                    (and
                      (not this.isPrivateMessage)
                      (or this.visible this.featured)
                    )
                  }}
                    <dropdown.item class="topic-admin-pin">
                      <DButton
                        class="btn-transparent"
                        @label={{if
                          this.featured
                          "topic.actions.unpin"
                          "topic.actions.pin"
                        }}
                        @action={{fn this.onButtonAction "showFeatureTopic"}}
                        @icon="thumbtack"
                      />
                    </dropdown.item>
                  {{/if}}

                  {{#if
                    (and
                      this.details.can_archive_topic (not this.isPrivateMessage)
                    )
                  }}
                    <dropdown.item class="topic-admin-archive">
                      <DButton
                        class="btn-transparent"
                        @label={{if
                          this.archived
                          "topic.actions.unarchive"
                          "topic.actions.archive"
                        }}
                        @action={{fn this.onButtonAction "toggleArchived"}}
                        @icon="folder"
                      />
                    </dropdown.item>
                  {{/if}}

                  {{#if this.details.can_toggle_topic_visibility}}
                    <dropdown.item class="topic-admin-visible">
                      <DButton
                        class="btn-transparent"
                        @label={{if
                          this.visible
                          "topic.actions.invisible"
                          "topic.actions.visible"
                        }}
                        @action={{fn this.onButtonAction "toggleVisibility"}}
                        @icon={{if this.visible "far-eye-slash" "far-eye"}}
                      />
                    </dropdown.item>
                  {{/if}}

                  {{#if (and this.details.can_convert_topic)}}
                    <dropdown.item class="topic-admin-convert">
                      <DButton
                        class="btn-transparent"
                        @label={{if
                          this.isPrivateMessage
                          "topic.actions.make_public"
                          "topic.actions.make_private"
                        }}
                        @action={{fn
                          this.onButtonAction
                          (if
                            this.isPrivateMessage
                            "convertToPublicTopic"
                            "convertToPrivateMessage"
                          )
                        }}
                        @icon={{if this.isPrivateMessage "comment" "envelope"}}
                      />
                    </dropdown.item>
                  {{/if}}

                  <dropdown.divider />

                  {{#if this.currentUser.canManageTopic}}
                    <dropdown.item class="admin-topic-timer-update">
                      <DButton
                        class="btn-transparent"
                        @label="topic.actions.timed_update"
                        @action={{fn this.onButtonAction "showTopicTimerModal"}}
                        @icon="far-clock"
                      />
                    </dropdown.item>

                    {{#if this.currentUser.staff}}
                      <dropdown.item class="topic-admin-change-timestamp">
                        <DButton
                          class="btn-transparent"
                          @label="topic.change_timestamp.title"
                          @action={{fn
                            this.onButtonAction
                            "showChangeTimestamp"
                          }}
                          @icon="calendar-alt"
                        />
                      </dropdown.item>
                    {{/if}}

                    <dropdown.item class="topic-admin-reset-bump-date">
                      <DButton
                        class="btn-transparent"
                        @label="topic.actions.reset_bump_date"
                        @action={{fn this.onButtonAction "resetBumpDate"}}
                        @icon="anchor"
                      />
                    </dropdown.item>

                    <dropdown.item class="topic-admin-slow-mode">
                      <DButton
                        class="btn-transparent"
                        @label="topic.actions.slow_mode"
                        @action={{fn
                          this.onButtonAction
                          "showTopicSlowModeUpdate"
                        }}
                        @icon="hourglass-start"
                      />
                    </dropdown.item>
                  {{/if}}

                  {{#if (or this.currentUser.staff this.extraButtons.length)}}
                    <dropdown.divider />

                    {{#if this.currentUser.staff}}
                      <dropdown.item class="topic-admin-moderation-history">
                        <DButton
                          class="btn-transparent"
                          @label="review.moderation_history"
                          @href={{this.topicModerationHistoryUrl}}
                          @icon="list"
                        />
                      </dropdown.item>
                    {{/if}}

                    {{#each this.extraButtons as |button|}}
                      <dropdown.item>
                        <DButton
                          @label={{button.label}}
                          @translatedLabel={{button.translatedLabel}}
                          @icon={{button.icon}}
                          class={{concatClass
                            "btn-transparent"
                            button.className
                          }}
                          @action={{fn this.onExtraButtonAction button.action}}
                        />
                      </dropdown.item>
                    {{/each}}
                  {{/if}}
                </DropdownMenu>
              </:content>
            </DMenu>
          </span>
        </span>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
