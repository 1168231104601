import { htmlSafe } from "@ember/template";
import { autoUpdatingRelativeAge } from "discourse/lib/formatter";
import { registerRawHelper } from "discourse-common/lib/helpers";

/**
  Display logic for dates. It is unbound in Ember but will use jQuery to
  update the dates on a regular interval.
**/

registerRawHelper("format-date", formatDate);
export default function formatDate(val, params = {}) {
  let leaveAgo,
    format = "medium",
    title = true;

  if (params.leaveAgo) {
    leaveAgo = params.leaveAgo === "true";
  }
  if (params.format) {
    format = params.format;
  }
  if (params.noTitle) {
    title = false;
  }

  if (val) {
    // // 将时间戳字符串转换为Date对象
    // const timestamp = new Date(val);
    // // 获取当前时间
    // const now = new Date();
    // // 计算时间差（以小时为单位）
    // const diffInHours = Math.floor((now - timestamp) / (1000 * 60 * 60));
    // return diffInHours
    let date = new Date(val);
    return htmlSafe(
      autoUpdatingRelativeAge(date, {
        format,
        title,
        leaveAgo,
        prefix: params.prefix,
      })
    );
  }
}
