import { ajax } from "discourse/lib/ajax";
import EmberObject from "@ember/object";
export async function  ReviewUGC(main) {
    let data={
      content:main
    }
  let result= await ajax(
      `/moyun/ugc`,
      {
        type: "POST",
        data,
      }
    )
    if (result.result.result.label == 'ad') {
      return ''
  }else if(result.result.result.label=='politics'){
     return ''
  }else{
    if (result.result.result.suggestion == 'block') {
      return  EmberObject.create({
          failed: true,
          reason: 'The content you posted contains non-compliant or prohibited words. Please revise and resubmit.',
        })
    } else if (result.result.result.suggestion == 'review') {
      return  EmberObject.create({
          failed: true,
          reason: 'The content you posted contains non-compliant or prohibited words. Please revise and resubmit.',
        })
    } else {
      return ''
    }
  }

}
