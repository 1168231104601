import DiscourseRoute from "./discourse";
import Group from "discourse/models/group";

export default DiscourseRoute.extend({

  model() {
    return Group.create({
      automatic: false,
      visibility_level: 0,
      can_admin_group: true,
    });
  },

  setupController(controller, model) {
    controller.set("model", model);
  },

  renderTemplate() {
    this._super(...arguments); // 确保调用父类的renderTemplate方法
    this.renderTemplate('apply', {
      into: 'application', outline: 'main'
    });
  }


});
