import Controller from '@ember/controller';
import $ from 'jquery';
import { ajax } from 'discourse/lib/ajax';
import discourseDebounce from 'discourse-common/lib/debounce';
import getURL from 'discourse-common/lib/get-url';
import { convertIconClass } from 'discourse-common/lib/icon-library';
import I18n from 'discourse-i18n';
import { tracked } from '@glimmer/tracking';
import { popupAjaxError } from "discourse/lib/ajax-error";
import EmberObject, { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import discourseComputed, { bind } from "discourse-common/utils/decorators";
import { service } from "@ember/service";



export default class FlairController extends Controller {

  @service router;
  @service dialog;
  @service currentUser;
  @tracked instr = '';
  @tracked phoneTel = '';
  @tracked userName = '';
  @tracked saving = true;
  @tracked model = {
    flair_type: "icon",
    flair_icon: "some-icon",
    flair_url: ""
  };

  get demoAvatarUrl() {
    return getURL('/images/avatar.png');
  }

  get flairPreviewIcon() {
    return this.model.flair_type === 'icon';
  }

  get flairPreviewIconUrl() {
    return this.model.flair_icon ? convertIconClass(this.model.flair_icon) : '';
  }

  get flairPreviewImage() {
    return this.model.flair_type === 'image';
  }

  get flairImageUrl() {
    return this.model.flair_url && this.model.flair_url.includes('/') ? this.model.flair_url : null;
  }

  get flairPreviewLabel() {
    const key = this.flairPreviewImage ? 'image' : 'icon';
    return I18n.t(`groups.flair_preview_${key}`);
  }


  _loadIcon() {
    if (!this.model.flair_icon) {
      return;
    }

    const icon = convertIconClass(this.model.flair_icon),
      c = '#svg-sprites',
      h = 'ajax-icon-holder',
      singleIconEl = `${c} .${h}`;

    if (!icon) {
      return;
    }

    if (!$(`${c} symbol#${icon}`).length) {
      ajax(`/svg-sprite/search/${icon}`).then(data => {
        if ($(singleIconEl).length === 0) {
          $(c).append(`<div class="${h}">`);
        }

        $(singleIconEl).html(
          `<svg xmlns='http://www.w3.org/2000/svg' style='display: none;'>${data}</svg>`
        );
      });
    }
  }

  @action
  _loadSVGIcon() {
    if (this.model.flair_icon) {
      discourseDebounce(this, this._loadIcon, 1000);
    }
  }

  @action
  setFlairImage(event) {
    const upload = event.target.files[0];
    if (upload) {
      const fileUrl = URL.createObjectURL(upload);
      this.model.flair_url = getURL(fileUrl);
      this.model.flair_upload_id = upload.id;
    }
  }

  @action
  removeFlairImage() {
    this.model.flair_url = null;
    this.model.flair_upload_id = null;
  }

  // 表单请求
  @action
  save() {
    this.set("saving", true);
    // console.log(this.currentUser.username, '当前用户1111');

    let markdownContent = `
      ### 姓名
      ${this.userName}

      ### 联系电话
      ${this.phoneTel}

      ### 申请理由
      ${this.instr}
      `;


    const data = {};
    data.raw = markdownContent;

    const currentDate = new Date();
    const formattedDate = currentDate.getFullYear() + "-" +
                      String(currentDate.getMonth() + 1).padStart(2, '0') + "-" +
                      String(currentDate.getDate()).padStart(2, '0') + " " +
                      String(currentDate.getHours()).padStart(2, '0') + ":" +
                      String(currentDate.getMinutes()).padStart(2, '0') + ":" +
                      String(currentDate.getSeconds()).padStart(2, '0');
    // 拼接姓名和时间戳
    data.title = 'New Group Application' + "-" + formattedDate;
    data.category = "11";
    // if (process.env.NODE_ENV === 'production') {
    //   console.log('This is production environment.');
    // }
    // else if (process.env.NODE_ENV === 'development') {
    //   console.log('This is development environment.', data);

    // }
    // else {
    //   console.log('Unknown environment.');
    // }
    ajax(`/posts`, {
      type: "POST",
      data,
    }).then((result) => {
      this.dialog.alert({
        message: 'We have received your application and will contact you later, Thank you.',
        didConfirm: () => location.href = '/about',
        didCancel: () => location.href = '/about',
      });
    })
      .catch((err) => {
        console.log(err, '提交失败');
      });
  }

  @action
  updateSavingState() {
    if (!isEmpty(this.instr) && !isEmpty(this.phoneTel) && !isEmpty(this.userName) && this.phoneTel.match(/^1[3456789]\d{9}$/)) {
      this.saving = false;
    } else {
      this.saving = true;
    }
  }

  // 手机号的校验
  @discourseComputed(
    "phoneTel",
    "forceValidationReason"
  )
  phoneNumberValidation(
    phoneNumber,
    forceValidationReason
  ) {
    const failedAttrs = {
      failed: true,
      ok: false,
      element: document.querySelector("#new-account-phone"),
    };

    // If blank, fail without a reason
    if (isEmpty(phoneNumber)) {
      return EmberObject.create(
        Object.assign(failedAttrs, {
          message: "user phone_number required",
          reason: forceValidationReason ? "user phone_number required" : null,
        })
      );
    }

    if (!phoneNumber.match(/^1[3456789]\d{9}$/)) {
      return EmberObject.create(
        Object.assign(failedAttrs, {
          reason: "user phone_number invalid",
        })
      );
    }



    // return EmberObject.create({
    //   ok: true,
    //   reason: "phoneNumber authenticated",
    // });
    return null
  }
}
