/* import __COLOCATED_TEMPLATE__ from './group-membership-button.hbs'; */
import Component from "@ember/component";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import RequestGroupMembershipForm from "./modal/request-group-membership-form";
import { action } from "@ember/object";
import { setting } from "discourse/lib/computed";
import Category from "discourse/models/category";
import Composer from "discourse/models/composer";
export default Component.extend({
  classNames: ["group-membership-button"],
  appEvents: service(),
  currentUser: service(),
  dialog: service(),
  modal: service(),
  router: service(),
   composer: service(),
   fixedCategoryPositionsOnCreate: setting("fixed_category_positions_on_create"),
  @discourseComputed("model.public_admission", "userIsGroupUser")
  canJoinGroup(publicAdmission, userIsGroupUser) {
    return publicAdmission && !userIsGroupUser;
  },

  @discourseComputed("model.name")
  isGroupOwner() {
    if(this.model.is_group_owner) {
      return false
    }
    return true;
  },

  @discourseComputed("model.name")
  isFlag() {
    return  this.model.is_group_owner ?? false;
  },

  @discourseComputed("model.name")
  groupPath() {
    return  this.model.name;
  },

  @discourseComputed("model.public_exit", "userIsGroupUser")
  canLeaveGroup(publicExit, userIsGroupUser) {
    return publicExit && userIsGroupUser;
  },

  @discourseComputed("model.allow_membership_requests", "userIsGroupUser")
  canRequestMembership(allowMembershipRequests, userIsGroupUser) {
    return allowMembershipRequests && !userIsGroupUser;
  },

  @discourseComputed("model.is_group_user")
  userIsGroupUser(isGroupUser) {
    return !!isGroupUser;
  },

  _showLoginModal() {
    this.showLogin();
    cookie("destination_url", window.location.href);
  },

  removeFromGroup() {
    const model = this.model;
    model
      .leave()
      .then(() => {
        model.set("is_group_user", false);
        this.appEvents.trigger("group:leave", model);
          window.location.reload();
      })
      .catch(popupAjaxError)
      .finally(() => this.set("updatingMembership", false));
  },
  @action
  opennewtopic() {
    const categories = this.fixedCategoryPositionsOnCreate
    ? Category.list()
    : Category.listByActivity();
   let categoryId = categories.filter(item=>item.name==this.model.full_name)
    if (this.currentUser) {
      if (this.isMobile) {
        this.composer.openNewTopic({
          preferDraft: true,
          category: {
            id: categoryId[0].id,
          },
        });
      } else {
        window.localStorage.setItem('categoryId',categoryId[0].id)
        this.router.transitionTo("newtopic");
        this.composer.open({
          action: Composer.CREATE_TOPIC,
          draftKey: Composer.NEW_TOPIC_KEY,
          replyflg: true,
          categoryId:categoryId[0].id,
        });
      }
    } else {
      localStorage.setItem('previousPage', window.location.href)
      // this.router.transitionTo("login");
      this.router.transitionTo("/userlogin");
    }
  },
  get isMobile() {
    // 这是一个简单的正则表达式来检测常见的移动端User Agent。
    // 在实际应用中，你可能需要使用更全面的库或服务来检测设备。
    const mobileUserAgent = /Mobi|Android/i.test(navigator.userAgent);
    return mobileUserAgent || window.innerWidth <= 768; // 也可以结合屏幕宽度判断
  },
  actions: {
    joinGroup() {
      if (this.currentUser) {
        this.set("updatingMembership", true);
        const group = this.model;

        group
          .join()
          .then(() => {
            group.set("is_group_user", true);
            this.appEvents.trigger("group:join", group);
            window.location.reload();
          })
          .catch(popupAjaxError)
          .finally(() => {
            this.set("updatingMembership", false);
          });
      } else {
        // this._showLoginModal();
        localStorage.setItem('previousPage', window.location.href)
        this.router.transitionTo("/userlogin");
      }
    },

    leaveGroup() {
      this.set("updatingMembership", true);

      if (this.model.public_admission) {
        this.removeFromGroup();
      } else {
        return this.dialog.yesNoConfirm({
          message: I18n.t("groups.confirm_leave"),
          didConfirm: () => this.removeFromGroup(),
          didCancel: () => this.set("updatingMembership", false),
        });
      }
    },

    showRequestMembershipForm() {
      if (this.currentUser) {
        this.modal.show(RequestGroupMembershipForm, {
          model: {
            group: this.model,
          },
        });
      } else {
        this._showLoginModal();
      }
    },
  },

});
