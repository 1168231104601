import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { addExtraUserClasses } from "discourse/helpers/user-avatar";
import { avatarImg } from "discourse-common/lib/avatar-utils";
import { registerRawHelper } from "discourse-common/lib/helpers";
import { ajax } from "discourse/lib/ajax";

// '786ea0000bd0000009829f0e7242, 4ad5d0000bd0000009829cf2c6f4, d68960000bd0000009829c76db6b',


registerRawHelper("moyunAvatar", moyunAvatar);
export default function moyunAvatar(openids) {

  let getmsg = localStorage.getItem(`${openids}`);
  if (getmsg) {
    let userMsg = JSON.parse(getmsg);
    // console.log('userMsg',userMsg);
    let avatarURL;
    if (userMsg.head_url) {
      avatarURL = userMsg.head_url;
    } else {
      avatarURL = "/images/default_avatar.png";
    }
    return htmlSafe(`<img loading='lazy' alt='' width='34' height='34' src='${avatarURL}' class='avatar moyun-avatar-img'>`);

  } else {

    ajax("/moyun/s_other_profile", {
      type: "POST",
      data: { openids },
    }).then((res) => {
      // console.log('res',res);
      // res.data[0].head_url = "https://passport.obs.cn-north-1.myhwclouds.com/prod/edb7b5180543320da8d6aa50e17c7ba2/d6f95d6a65c398394423854b.jpeg";
      localStorage.setItem(res.data[0].openid, JSON.stringify(res.data[0]));

      let avatarURL;
      if (res.data[0].head_url) {
        avatarURL = res.data[0].head_url;
      } else {
        avatarURL = "/images/default_avatar.png";
      }
      return htmlSafe(`<img loading='lazy' alt='' width='34' height='34' src='${avatarURL}' class='avatar moyun-avatar-img'>`);
    })
  }
}
