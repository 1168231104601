import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias3((lookupProperty(helpers,"raw")||(depth0 && lookupProperty(depth0,"raw"))||alias2).call(alias1,"topic-list-header-column",{"name":"raw","hash":{"ariaLabel":(lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sr_activity",{"name":"i18n","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":26,"column":106},"end":{"line":26,"column":126}}}),"name":"oldest","order":"activity","number":"true","sortable":"sortable"},"hashTypes":{"ariaLabel":"SubExpression","name":"StringLiteral","order":"StringLiteral","number":"StringLiteral","sortable":"PathExpression"},"hashContexts":{"ariaLabel":depth0,"name":depth0,"order":depth0,"number":depth0,"sortable":depth0},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":26,"column":0},"end":{"line":26,"column":128}}}))
    + alias3((lookupProperty(helpers,"raw-plugin-outlet")||(depth0 && lookupProperty(depth0,"raw-plugin-outlet"))||alias2).call(alias1,{"name":"raw-plugin-outlet","hash":{"name":"topic-list-header-after"},"hashTypes":{"name":"StringLiteral"},"hashContexts":{"name":depth0},"types":[],"contexts":[],"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":27,"column":54}}}));
},"useData":true});

addRawTemplate("topic-list-header", template, { core: true });
export default template;