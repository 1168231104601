/* import __COLOCATED_TEMPLATE__ from './user-profile-avatar.hbs'; */
import Component from "@ember/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import AvatarSelectorModal from "discourse/components/modal/avatar-selector";
import discourseComputed from "discourse-common/utils/decorators";

export default Component.extend({
  modal: service(),
  currentUser: service(),

  @discourseComputed("user")
  isAnOwner() {
    // if (!this.user.groups.length) {
    //   return false;
    // }
    // let res = this.user.groups.some(item => item.full_name == "Group Owner");
    // return res;
    return this.user.isModerator;
  },

  @action
  showAvatarSelector(user) {
    if( this.currentUser.avatar_changeable) {
      this.modal.show(AvatarSelectorModal, {
        model: { user },
      });
    }
  }
});
