import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";

let template = compiler({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"bottom-topicinfo\">\n  <div class=\"topic-num\">\n    <a href=\"javascript:void(0)\" class=\" num-likes click-likes "
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.likeclass",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":82}}}))
    + "\"\n      data-topic-id=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.relative_post_id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":47}}}))
    + "\">\n      "
    + alias2((lookupProperty(helpers,"d-icon")||(depth0 && lookupProperty(depth0,"d-icon"))||alias3).call(alias1,"thumbs-up",{"name":"d-icon","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":28}}}))
    + "\n      <span class=\"likenum\">\n        "
    + alias2(lookupProperty(helpers,"get").call(alias1,"view.topic.op_like_count",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":36}}}))
    + "\n      </span>\n\n    </a>\n    <a href="
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.summaryUrl",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":32}}}))
    + " class=\"num-likes\">\n      <img src=\"/images/posted-new.png\" alt=\"\" style=\"margin-right: 10px;width:15px\">\n      "
    + alias2(lookupProperty(helpers,"get").call(alias1,"view.topic.reply_count",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":32}}}))
    + "\n    </a>\n  </div>\n  <div>\n    <a href=\"javascript:void(0)\" class=\"num-likes click-share\" data-topic-slug=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.slug",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":17,"column":80},"end":{"line":17,"column":94}}}))
    + "\"\n      data-topic-id=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"topic.id",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":18,"column":21},"end":{"line":18,"column":33}}}))
    + "\">\n      "
    + alias2((lookupProperty(helpers,"d-icon")||(depth0 && lookupProperty(depth0,"d-icon"))||alias3).call(alias1,"share",{"name":"d-icon","hash":{},"hashTypes":{},"hashContexts":{},"types":["StringLiteral"],"contexts":[depth0],"data":data,"loc":{"start":{"line":19,"column":6},"end":{"line":19,"column":24}}}))
    + "\n      <span style=\"font-size: 14px;  font-family: Montserrat; color: #8194A5 !important;\">\n        share\n      </span>\n    </a>\n  </div>\n</div>\n";
},"useData":true});

addRawTemplate("list-function", template, { core: true });
export default template;