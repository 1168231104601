import DiscourseRoute from "discourse/routes/discourse";
import { log } from "qunit";

export default DiscourseRoute.extend({
  titleToken() {
    return [this.modelFor("group").get("name")];
  },

  async model(params) {
    this.store.find("group", params.name).then(res => console.log('/routes/group.js',res));
    return await this.store.find("group", params.name);
  },

  serialize(model) {
    return { name: model.get("name").toLowerCase() };
  },

  setupController(controller, model) {
    // console.log('bon',model);
    controller.set("model", model);
  },
});
