import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { gt } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";

export default Controller.extend({
  currentUser: service(),
  faqOverridden: gt("siteSettings.faq_url.length", 0),

  @discourseComputed("model.contact_url", "model.contact_email")
  contactInfo(url, email) {
  if(url) {
    return I18n.t("about.contact_info", {
      contact_info: `<a href='${url}' target='_blank'>${url}</a>`,
    });
  } else if(email) {
    return I18n.t("about.contact_info", {
      contact_info: email,
    });
  } else {
    return null;
  }
},


  @action
  toApply() {
  if(this.currentUser) {
  location.href = "/apply";
}else {
  location.href = "/userlogin";
}
}
});
