/* import __COLOCATED_TEMPLATE__ from './menu-item.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression } from "discourse/lib/utilities";

export default class UserMenuItem extends Component {
  get className() {
    return this.#item.className;
  }

  get linkHref() {
    return this.#item.linkHref;
  }

  get linkTitle() {
    return this.#item.linkTitle;
  }

  get icon() {
    return this.#item.icon;
  }

  get label() {
    if (this.#item.label == 'Queue') return 'Pending Review ';
    if (this.#item.linkTitle == 'post approved') return 'Approved';
    return this.#item.label;
  }

  get option() {
    if (this.#item.className == 'bookmark') return 'bookmarked';

    switch (this.#item.linkTitle) {
      case 'new like':
        return 'liked';
        break;
      case 'new reply':
        return 'replied';
        break;
      case 'new private message':
        return 'messaged';
        break;
      case 'Message':
        return 'messaged';
        break;
      case 'edited':
        return 'edited';
        break;
      case 'new post':
        return 'posted';
        break;
      case 'mentioned':
        return 'mentioned';
        break;
      case 'your post was marked as solution':
        return 'marked';
        break;
      default:
        return  '';
        break;
    }
  }

  get labelClass() {
    return this.#item.labelClass;
  }

  get created_at() {
    if (this.#item.notification){
      return this.#item.notification.created_at;
    } else if (this.#item.bookmark) {
      return this.#item.bookmark.created_at;
    } else if ( this.#item.message) {
      return this.#item.message.created_at;
    } else {
      return this.#item.reviewable.created_at;
    }
  }

  get description() {
    const description = this.#item.description;
    if (description) {
      if (typeof description === "string") {
        // do emoji unescape on all items
        return htmlSafe(emojiUnescape(escapeExpression(description)));
      }
      // it's probably an htmlSafe object, don't try to unescape emojis
      return description;
    }
  }

  get descriptionClass() {
    return this.#item.descriptionClass;
  }

  get topicId() {
    return this.#item.topicId;
  }

  get iconComponent() {
    return this.#item.iconComponent;
  }

  get iconComponentArgs() {
    return this.#item.iconComponentArgs;
  }

  get endComponent() {
    return this.#item.endComponent;
  }

  get endOutletArgs() {
    return this.#item.endOutletArgs;
  }

  get #item() {
    return this.args.item;
  }

  @action
  onClick(event) {
    return this.#item.onClick({
      event,
      closeUserMenu: this.args.closeUserMenu,
    });
  }
}
