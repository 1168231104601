import { template } from "@ember/template-compiler";
import Component from "@ember/component";
import PluginOutlet from "discourse/components/plugin-outlet";
import { ajax } from "discourse/lib/ajax";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
export default class OnlineCard extends Component {
    onlineData = [];
    @tracked
    topicTotal = 0;
    @tracked
    replyTotal = 0;
    @tracked
    userTotal = 0;
    constructor(){
        super(...arguments);
        this.getDashBoardData();
    }
    // @action
    // async getDashBoardData() {
    //   const result = await ajax("/directory_items?period=all")
    //   console.log("result", result);
    //   this.userTotal = result.directory_items.length;
    //   result.directory_items.forEach(item => {
    //     this.topicTotal += item.post_count;
    //     this.replyTotal += item.topic_count;
    //   });
    // }
    @action
    async getDashBoardData() {
        ajax(`/moyun/summary`, {
            type: "POST",
            data: {
                mobile: "13761711151"
            }
        }).then((res1)=>{
            this.topicTotal = res1.post_cnt;
            this.replyTotal = res1.reply_cnt;
            this.userTotal = res1.creator_cnt;
        });
    }
    @action
    reportUrl(type1, start_date1, end_date1) {
        start_date1 = moment.utc(start_date1).locale("en").format("YYYY-MM-DD");
        end_date1 = moment.utc(end_date1).locale("en").format("YYYY-MM-DD");
        return getURL(`/admin/reports/${type1}?start_date=${start_date1}&end_date=${end_date1}`);
    }
    static{
        template(`
    <div class="online-wrapper">
      <div class="txt">
        Collaborate with a community of creators who are building the future of
        online conversations
      </div>
      <div class="databoard-box">
        <div class="databoard-wrapper">
          <div class="databoard">
            <div class="item"><span
                class="keywords"
              >{{this.topicTotal}}</span>Conversations</div>
            <div class="item"><span
                class="keywords"
              >{{this.replyTotal}}</span>Replies</div>
            <div class="item"><span
                class="keywords"
              >{{this.userTotal}}</span>Members</div>
          </div>
        </div>
      </div>
      <div class="avatarBox-wrapper">
        <div class="avatarBox">
          <PluginOutlet @name="discovery-list-container-top" />
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
