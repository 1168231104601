import Controller from '@ember/controller';

export default class WeeklyQuizController extends Controller {
    // Define groups data
    groups = [
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/a496/d4a2/e22968505aea43fcdf34602120265858?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c1qj8deK01uBA3yWsIljQxKgB93niUjz9VFv7SNfjjuMO3HfD05FmBGCSayvPB2W6qd71Ui69xZdK4Ac9PMCCMJkqd8brhZdGdjvd1K6rdLcPJMkWvIPGdvbhfMeyRSK8r~F2ZU4~3t5hDupZxs-4vLs8LBZ1VlWFIAvCfi5JubHlg3xh65~radgqMo9f6M~NSoa3UJxG3zeNmCGU-Wwrb5PfrK9mAZ3ONXZ6YVDh2Xow8exw8r6gRrES7uarEcETzzS7sJLTnIkXZBVHltOczrn4mpne5jZ2LPzUB40v3qprBv73HY5Tyti1Y3lJoqjMwxkmusJw7R0mLQLrW8dvw__',
            title: 'Shang Hai',
            description: 'Public group ',
            mount: 842
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/3165/c168/10e0a34e444ff736ac4b28a9e92f67eb?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Oy0qIfVw3r~1r95TdlU2T7b-gh~xVbBcFh11Pbr87bbDrYK9bEE2FuIuirjAWGWfhsebBndg6~LcXsTRwNncbFOlBmfoUe1BlnYrjj-XZ94rH75Qo9KLexNNiNCYh2J8rYDr~QwT9DPnRSLaw1S-yt8YDzBJV16FdKk4yfqslnwqEtQwb3~o3x5RP7aLZqavDvgBVmkxn53uEUQYkaLtpOL02GT0ZwISDAzE0szdm61iVqhFF9Ez0oiucT8eAgHJoKpoHg9R5S0BCAofnU3QIbzIVBAhEFOrfNJLtzPGsuqClMdIO7Uq2kt9C1YPis672fDs36RCyZhiMH1wTGupOw__',
            title: 'NYC Chapter: In-Person User Group',
            description: 'Public group ',
            mount: 171
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/207b/255a/8b2d0ad14c9b5ec6b7fb7079d00b7974?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=URQsHWxJXHb6zHV1qfSKodUlFMM-7GYZvu9H9mQwLbB3OaohpZ~3Y-8ib3j4rPazhBoxeXyPOuEBBBnIhfatQS2rliIXtzZkA0RSzKkuGoXzDJAZNJkJrw6eCnwu8fMDALao~zrhlFOGWV8KjfGxz1wPcfP7JBiFq5dDgav3Jv9RCIDM5jCtsCJVrV8cBrKQ4E4PQy-xW9y1F5dNJYxFytfBX9GpV-9dHzm4ka3tbUqrLXxEkFO7so7jrjX1u5MHS~aESKbbKwJxP9vpqkCgZ8dIg8dLFen0JnD6GZUlRauWB5EQrieuSzNgKhxoKtFF0POTfFaVGKl0gtlAHx2E0w__',
            title: 'D-A-CH Community Gruppe',
            description: 'Private group  ',
            mount: 203
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/fcb6/b1af/5b1d9a795e1cd1411f2d75cb6fc07a8c?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iP1NJuJZtP72JXLnvY-rOdtahM7VoNkMxJfq42Y1ia~6oZzByy~jaFocRT74eqUAv0G7YzrmT9iiqpa5a4v77yxwAVsY5nfpMemGMFrC6owcHOKXLeTsf6iUjw2iIiWWb93nuclkM2M5YHkbDFLiuS1APNrgZmO5eEIPtLE0N-q2RCkdexasKQ-YgO0CMfJWyu8PlmTRDVBbmmDCS4kUzf3gDuqnpq-d42Mrh-43l6G2coX6AXCKB0vZwcANRIlzYBmKMqRBxBLXXzv4-bltpoL9EFqxUXptr6gfOW8Ci7ZIWG~worwPlxZpDBhbohXIp8sA1Zhp6Vitqmv0DkrIUw__',
            title: 'Radarly User Group - Coming Soon',
            description: 'Public group  ',
            mount: 74
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/2ac6/83ed/eaaea7f5087adf76e6fa110728e8fad7?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TR8btMgKnXtMbR8HRs69V0GqZoXn7odls6gHD6mIDTfNuINdrWVUZwghmdsD7gXXAw3Ff1-iSzGfkpn4UEJaxrncNO-Dodbo61Yi~1IgZyqimnM-QaEtMZZostwwNwL-cim7vwvXcczVwo6xUdFm1B7zteagQX2lWC4EbscHTcupV~0NHbJkzwdGL-sihEAAnTPy-o8QMoDyuRkgq4WFmx8kN8~kIwte5WJyRJJ2GNzx~vue6XtxWZcD3tBbzEzVncKJ~ywmDQMh2AAQ65WRxl2rP~7d~L~7ossjYXmjZ-YClV-DRqkCuVgeMv2ygnU2MhuKyHybzCCue~jhkNlORA__',
            title: 'UPLIFT: Connecting LGBTQ+ and Allied Professionals',
            description: 'Public group  ',
            mount: 31
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/7a4a/d41d/ad9d328b1e00ad59327d80ca5565aab2?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=C3M1aHkOdbvYim7-3sGqk9blgjep4Gb3OE4~ZeDq8jBp1olTRBIoer4TnlPTR9a0VhN7ldY-v2P2xLND4bkEtIp2b9oMSjqNm5npYId1aE~e2IGu8WeynU-3vZ7R~gdQ-uxfSzfvH97WY-ppOJUHtrx6tORNxD1WybiDKwvbzNGhKQUho2nPc37~rNGdg8AXk0SQvrvRj5wY6xtEHwG5hCQIL8yUtm84f9SWP6cmnUo49lCZwb6GyWCR40PhDEAYWBH0A0gEEv8s-9yQUBDH-bzLrdnU3xpNFSK-gu3i5Y~E5tVp0XvPYvRCYKcKe413pZgBm69omzwuyYADAVMF9g__',
            title: 'Australia Chapter: User Group',
            description: 'Private group  ',
            mount: 236
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/6e5b/9200/10342d43494cbfc68737083d6f2d29a0?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=p4kEwejCw~SE4~OJbXWI5VSOnrxcOyQ2trISp4scv-H~4ouWKAp2wLVvIQ-Ia2JFCDrHqj3yI8dPloir38J7phIFMEdiI~N270gkzTAQ3XxHWGqhWPk5ho2Tw95kZ62bd6A37TAfltigMQqKMeRH4u1q8W7krVbeqehlJ87xGXt~aO7Wq9RYi2jiwM4mEQdP9sWLKTHBgYy7-AqZCmQV2~3sk29IhCPWhvdUuUEBpAWvS03S4F7291GK2UzrI0eoNq10yHaXVVefYJS9UXVJq~4Ontnkm44ORULLWLbAw080nKGwb29Z6fw1vRXVXqNdFpTu5W2rPTbt5MICJGTAUg__',
            title: 'NYC Chapter: In-Person User Group',
            description: 'Public group  ',
            mount: 80
        },
        {
            bgImage: 'https://s3-alpha-sig.figma.com/img/5b00/4c2f/23aba4817948e4038b5a2116b6c49029?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=i4PDmRYw5urkapdvdi5rGqJlyOPHcmbEaqt48nX6fOoy1v~9mDfRiWwticXr2aCQTRriMbL3kEsHOrDo3~vMWTaZ6HxQowa0H8HavAhtYQGeQHqFHhFtVEu7zx~cTp1upvNyOz-WVOiaeK5U1nRN-GNgVS733gOjDPMnkCxtaOfmhrxe3fWv31f~Wg5lYkdP16mqS~LcP3picQtttDrcTtU05xY4hHr6cBrARnlm7~YHjuObMuUteUWI-HQWgeuW7vd4iE9npKbFTAU5N~lE7uan1GtlQ4auNBaY86F~tLQtD~MM7EyRjGI85LVgbax6t8s1ZDme90HYiocM16eAcw__',
            title: 'Boolean Wizards Academy Group',
            description: 'Public group  ',
            mount: 124
        },
    ]
}
