import { template } from "@ember/template-compiler";
import Component from "@ember/component";
import { inject as service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
export default class Breadcrumb extends Component {
    @service
    router;
    @service
    breadcrumbs;
    @tracked
    routers = null;
    @action
    didInsertElement() {
        this.routers = this.breadcrumbs.items;
    }
    static{
        template(`
    <div class="breadcrumb-Component">
      <a href="/">
        <img src="/images/home.png" class="home" />
      </a>
      <a href="/" class="home-span">Community</a>
      {{#each this.routers as |parts|}}
        <a href={{parts.url}} class="params-span">〉{{parts.label}}</a>
      {{/each}}

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
