import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import { or } from "truth-helpers";
import ApiPanels from "./api-panels";
import Footer from "./footer";
import Sections from "./sections";
import logout from "discourse/lib/logout";
import DButton from "discourse/components/d-button";
import UserMenuProfileTabContent from "discourse/components/user-menu/profile-tab-content";
import { on } from "@ember/modifier";
import { concat, fn, hash } from "@ember/helper";
import { htmlSafe } from "@ember/template";
import { tracked } from "@glimmer/tracking";
import { addExtraUserClasses, renderAvatar } from "discourse/helpers/user-avatar";
import i18n from "discourse-common/helpers/i18n";
export default class SidebarHamburgerDropdown extends Component {
    @service
    appEvents;
    @service
    currentUser;
    @service
    site;
    @service
    siteSettings;
    @service
    sidebarState;
    @service
    router;
    @service
    composer;
    @service
    header;
    @service
    dialog;
    constructor(){
        super(...arguments);
    }
    @tracked
    LinkList = [
        {
            hrefUrl: "/",
            imgUrl: "/images/group-icon.png",
            activedImg: "/images/active-group-icon.png",
            name: "HOME",
            selected: false,
            showImg: "/images/group-icon.png"
        },
        {
            hrefUrl: "/usergroups",
            imgUrl: "/images/group-icon.png",
            activedImg: "/images/active-group-icon.png",
            name: "USER GROUP",
            selected: false,
            showImg: "/images/group-icon.png"
        },
        // {
        //   hrefUrl: "/faq",
        //   imgUrl: "/images/faq-icon.png",
        //   activedImg: "/images/active-faq-icon.png",
        //   name: "FAQ",
        //   selected: false,
        //   showImg: "/images/faq-icon.png",
        // },
        {
            hrefUrl: "/about",
            imgUrl: "/images/about-icon.png",
            activedImg: "/images/active-about-icon.png",
            name: "ABOUT",
            selected: false,
            showImg: "/images/about-icon.png"
        },
        {
            hrefUrl: "/weekly-quiz",
            imgUrl: "/images/quiz-icon.png",
            activedImg: "/images/active-quiz-icon.png",
            name: "WEEKLY QUIZ",
            selected: false,
            showImg: "/images/quiz-icon.png"
        }
    ];
    mySideList = [
        {
            name: "Users",
            hrefUrl: "/adminUsers"
        },
        {
            name: "Groups",
            hrefUrl: "/groups"
        },
        {
            name: "Categories",
            hrefUrl: "/categories"
        },
        {
            name: "Approve",
            hrefUrl: "/review"
        }
    ];
    avatarSize = "medium";
    @action
    triggerRenderedAppEvent() {
        this.appEvents.trigger("sidebar-hamburger-dropdown:rendered");
    }
    @action
    selectLink(selectedItem1) {
        console.log(selectedItem1);
    }
    @action
    opennewtopic() {
        if (this.currentUser) {
            const filteredGroups1 = this.currentUser.groups.filter((group1)=>group1.name.slice(0, 5) !== "trust");
            if (filteredGroups1.length == 0) {
                this.dialog.alert("You have not joined any groups yet~ / or please first join a certain interest group before posting~");
            } else {
                window.localStorage.removeItem("categoryId");
                if (this.isMobile) {
                    this.composer.openNewTopic({
                        preferDraft: true,
                        category: {
                            id: ""
                        }
                    });
                } else {
                    this.router.transitionTo("newtopic");
                    this.composer.open({
                        action: Composer.CREATE_TOPIC,
                        draftKey: Composer.NEW_TOPIC_KEY,
                        replyflg: true,
                        categoryId: ""
                    });
                }
            }
        } else {
            localStorage.setItem("previousPage", window.location.href);
            // this.router.transitionTo("login");
            this.router.transitionTo("/userlogin");
            this.header.hamburgerVisible = false;
        }
    }
    get collapsableSections() {
        if (this.siteSettings.navigation_menu === "header dropdown" && !this.args.collapsableSections) {
            return this.site.mobileView || this.site.narrowDesktopView;
        } else {
            this.args.collapsableSections;
        }
    }
    get avatar() {
        const avatarAttrs1 = addExtraUserClasses(this.currentUser, {});
        return htmlSafe(renderAvatar(this.currentUser, {
            imageSize: this.avatarSize,
            title: i18n("user.avatar.header_title"),
            template: this.currentUser.avatar_template,
            username: this.currentUser.username,
            name: this.siteSettings.enable_names && this.currentUser.name,
            ...avatarAttrs1
        }));
    }
    get isMobile() {
        // 这是一个简单的正则表达式来检测常见的移动端User Agent。
        // 在实际应用中，你可能需要使用更全面的库或服务来检测设备。
        const mobileUserAgent1 = /Mobi|Android/i.test(navigator.userAgent);
        return mobileUserAgent1 || window.innerWidth <= 768; // 也可以结合屏幕宽度判断
    }
    static{
        template(`
    <div class="hamburger-panel">
      <div
        {{didInsert this.triggerRenderedAppEvent}}
        data-max-width="320"
        class="revamped menu-panel drop-down"
      >
        <div class="panel-body">
          <div class="panel-body-contents">
            <div
              class="sidebar-hamburger-dropdown sidebar-hamburger-dropdown-xn"
            >

              {{#if this.isMobile}}
                {{! 移动端侧边栏内容 }}
                {{#if this.currentUser.username}}
                  <a
                    class="avatar-content"
                    href="/u/{{this.currentUser.username}}/activity/topics"
                  >
                    {{this.avatar}}
                    <div
                      class="sider-avatar-name"
                    >{{this.currentUser.name}}</div>
                  </a>
                {{else}}
                  <a href="/login" class="sider-avatar-top">
                    <img
                      class="sider-avatar-img"
                      src="/images/unLogin-avatar.png"
                      alt=""
                    />
                    <div class="sider-avatar-name">guest</div>
                  </a>
                {{/if}}

                <ul class="page-jump-content">
                  {{#each this.LinkList as |item|}}
                    <a
                      href={{item.hrefUrl}}
                      {{on "click" (fn this.selectLink item)}}
                      class="jump-group"
                    >
                      {{!-- <img src={{item.showImg}} alt="" class="page-jump-icon" /> --}}
                      <div class="page-jump-text">{{item.name}}</div>
                    </a>
                  {{/each}}
                  <a
                    class="red-button hamburgerbtn"
                    {{on "click" this.opennewtopic}}
                  >
                    <img src="/images/Add.jpg" alt="Add Icon" />
                    <span>
                      NEW TOPIC
                    </span>
                  </a>

                  {{! 管理员路由 }}
                  {{!-- {{#if this.currentUser.admin}}
                    {{#each this.mySideList as |item|}}
                    <a
                        href={{item.hrefUrl}}
                        {{on "click" (fn this.selectLink item)}}
                        class="jump-group {{if item.selected 'clicked' ''}}"
                      >
                        <div class="page-jump-text">{{item.name}}</div>
                      </a>
                    {{/each}}
                    {{if this.currentUser.admin "not_bottom-login-out"}}
                  {{/if}} --}}

                  {{!-- {{didInsert this.addClickHandlers}} --}}

                  {{#if this.currentUser}}
                    <div class="bottom-login-out">
                      <UserMenuProfileTabContent />
                    </div>
                  {{else}}
                    <a href="/login" class="bottom-login-out">
                      <img
                        src="/images/login-out-icon.png"
                        alt=""
                        class="page-jump-icon"
                      />
                      <div class="page-jump-text">LOG IN</div>
                    </a>
                  {{/if}}
                </ul>

              {{else}}
                {{! pc端内容 }}
                {{#if
                  (or this.sidebarState.showMainPanel @forceMainSidebarPanel)
                }}
                  <Sections
                    @currentUser={{this.currentUser}}
                    @collapsableSections={{this.collapsableSections}}
                    @panel={{this.sidebarState.currentPanel}}
                    @hideApiSections={{@forceMainSidebarPanel}}
                  />
                {{else}}
                  <ApiPanels
                    @currentUser={{this.currentUser}}
                    @collapsableSections={{this.collapsableSections}}
                  />
                {{/if}}

                <Footer />

              {{/if}}
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
