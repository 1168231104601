// app/controllers/WeeklyQuizController.js
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ajax } from "discourse/lib/ajax";

export default class WeeklyQuizController extends Controller {
  @tracked isEnd = false;
  @tracked quizList =[];
  @tracked listQuery = {
    page:1,
    limit:9,
    search:'',
    sortedBy:"desc",
    orderBy:"created_at",
  };

  init() {
    super.init(...arguments);
    this.getQuizList();
  }

  @action
  getQuizList(isLoadmore) {
    ajax("https://api.citynewsservice.cn/v1/quiz/list",{data:this.listQuery}).then((result) => {
      if(result.meta.pagination.total_pages == this.listQuery.page) this.isEnd = true;

      if (isLoadmore) {
        this.quizList = this.quizList.concat(result.data);
      } else {
        this.quizList = result.data;
      }
    });
  }

  @action
  loadMore() {
    this.listQuery.page = this.listQuery.page + 1;
    this.getQuizList(true);
  }

  @action
  handleScroll() {
    // 在滚动时执行的逻辑 xn
    const scrollPosition = window.scrollY || window.pageYOffset;
    // console.log('页面滚动距离：', scrollPosition);
    const header = document.querySelector(".d-header");
    if (scrollPosition <= 60) {
      header.style.background = 'transparent';
      let element = document.querySelector('.box-shadow');
      element.classList.remove('box-shadow');
    } else {
      header.style.background = '#ffffff';
      let element = document.querySelector('.d-header-wrap');
      element.classList.add('box-shadow');
    }

  }

  @action
  addScrollEventListener() {
    window.addEventListener('scroll', this.handleScroll);
  }

  @action
  removeScrollEventListener() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
